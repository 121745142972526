import React from 'react'
import { useForm } from 'react-hook-form'

import { Button, FormError, FormGroup, FormLabel } from '@cmpkit/base'
import UploadCloudIcon from '@cmpkit/icon/lib/glyph/upload-cloud'

export default function FormSame({ onSubmit }: any) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	return (
		<form onSubmit={handleSubmit(onSubmit)} className='py-5'>
			<FormGroup>
				<FormLabel>
					What is the most important parameter for indentifying this match is
					correct?
				</FormLabel>
				<select
					className='cmp-input'
					{...register('comment', { required: 'Option is requred' })}
				>
					<option value=''></option>
					<option value='picture'>Picture</option>
					<option value='description'>Description</option>
					<option value='title'>Title</option>
				</select>
				{errors?.comment?.message && (
					<FormError>{errors?.comment?.message as any}</FormError>
				)}
			</FormGroup>

			<div className='flex space-x-2'>
				<Button
					type='submit'
					variant='success'
					iconBefore={<UploadCloudIcon />}
					onClick={handleSubmit(onSubmit)}
				>
					Submit
				</Button>
			</div>
		</form>
	)
}
