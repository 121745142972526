import { motion } from 'framer-motion'
import React from 'react'

import { Loader } from '@cmpkit/base'

export default function FullscreenLoading() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className='fullscreen-loading text-xl font-semibold'
		>
			<Loader />
		</motion.div>
	)
}
