import cx from 'clsx'
import { first, uniq } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useQueryClient } from '@tanstack/react-query'

import {
	Button,
	Content,
	Footer,
	Header,
	InlineMessage,
	Layout,
	Loader,
	Panel,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'

import { useAuth } from '@/features/auth/authentication'
import { useInstructionAnswerMutation } from '@/features/instructions/mutations'
import { InstructionModel } from '@/features/instructions/types'

type WorkspaceQuizProps = {
	instructions: InstructionModel[]
	unit: any
}
export default function WorkspaceQuiz({
	instructions,
	unit,
}: WorkspaceQuizProps) {
	const { user } = useAuth()
	const queryClient = useQueryClient()
	const [activeInstruction, setActiveInstruction] = useState(
		first(instructions)?.id || 0
	)
	const answerMutation = useInstructionAnswerMutation({
		async onSuccess() {
			await queryClient.invalidateQueries({
				queryKey: ['instructions'],
			})
		},
	})
	const [completedIds, setCompletedIds] = useState<number[]>([])
	const sendAllAnswers = async () => {
		await answerMutation.mutateAsync(
			instructions.map((instruction) => ({
				user_id: (user as any).id,
				instruction_id: instruction.id,
			}))
		)
		toast.success('Successfull passed.', {
			duration: 5000,
			id: 'work',
		})
	}
	const tabs = useMemo(
		() =>
			instructions?.map((item: InstructionModel) => ({
				id: item.id,
				item,
			})) || [],
		[instructions]
	)
	const selectedInstruction = tabs.find(
		(item: any) => item.id === activeInstruction
	)?.item

	const handleReadQuizzClick = (id: number) => {
		mixpanel.track('Confirm instruction click')
		const completed = uniq([...completedIds, id])
		setCompletedIds(completed)
		if (completed.length === instructions.length) {
			sendAllAnswers()
		}
	}

	return (
		<Panel
			className='py-2 mx-auto overflow-hidden h-full relative'
			style={{ width: 800 }}
		>
			<Blanket
				className='backdrop-blur-lg bg-white/50 dark:bg-black/50 flex items-center justify-center absolute'
				isTinted={answerMutation.isPending}
			>
				<Loader />
			</Blanket>
			<Layout className='overflow-hidden h-full'>
				<Header className='px-5 py-2 flex items-center space-x-2 justify-between'>
					<h3 className='text-lg font-bold'>Required instruction</h3>
				</Header>
				<Content className='flex flex-col overflow-hidden'>
					<InlineMessage
						variant='warn'
						className='px-5 py-2 mx-5 items-center'
						icon={<AlertIcon />}
					>
						Please, read the instructions carefully before proceeding to the
						questions.
					</InlineMessage>
					<div className='flex items-center space-x-2 px-5 py-2 border-b border-base border-solid'>
						{tabs?.map(({ id, item }: any) => {
							const parameterKey = item.category_id
								? 'category'
								: item.competitor_id
									? 'competitor'
									: item.campaign_id
										? 'campaign'
										: null
							const parameterValue = parameterKey
								? unit[`${parameterKey}_name`]
								: null
							return (
								<div
									className={cx(
										'px-5 py-2 inline-flex items-center rounded-lg cursor-pointer border border-base border-solid hover:border-primary',
										{
											'border-primary bg-accent-4': activeInstruction === id,
										}
									)}
									key={id}
									onClick={() => {
										mixpanel.track('Go to next instruction')
										setActiveInstruction(id)
									}}
								>
									{completedIds.includes(id) && (
										<CheckIcon className='fill-success mr-2' />
									)}
									<div>
										<p className='text-muted text-xs'>
											Instruction
											{parameterKey && ` for ${parameterKey}`}
										</p>
										<h5 className='font-bold'>{parameterValue}</h5>
									</div>
								</div>
							)
						})}
					</div>
					{selectedInstruction && (
						<Layout className='overflow-hidden'>
							<Content className='w-full overflow-hidden h-full bg-accent-3'>
								<div className='p-5 space-y-2 overflow-y-auto w-full'>
									<div
										className='min-h-full'
										dangerouslySetInnerHTML={{
											__html: selectedInstruction.body,
										}}
									/>
								</div>
							</Content>
							<Footer className='px-5 py-5 border-t border-solid border-base flex justify-end'>
								{!completedIds.includes(activeInstruction) ? (
									<Button
										variant='success'
										size='large'
										type='button'
										iconBefore={<CheckIcon />}
										onClick={() => handleReadQuizzClick(activeInstruction)}
									>
										Ok, I got it
									</Button>
								) : (
									<Button
										variant='success'
										size='large'
										type='button'
										disabled
										iconBefore={<CheckIcon />}
									>
										Checked
									</Button>
								)}
							</Footer>
						</Layout>
					)}
				</Content>
			</Layout>
		</Panel>
	)
}
