import { AxiosError } from 'axios'
import { PromiseValue } from 'type-fest'

import {
	DefaultError,
	DefaultOptions,
	QueryCache,
	QueryClient,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions as NativeUseQueryOptions,
} from '@tanstack/react-query'

export const queryCache = new QueryCache()
const queryConfig: DefaultOptions = {
	queries: {
		refetchOnWindowFocus: false,
		staleTime: 60 * 1000 * 5,
	},
}
export const queryClient = new QueryClient({
	queryCache,
	defaultOptions: queryConfig,
})

export type ExtractFnReturnType<FnType extends (...args: any) => any> =
	PromiseValue<ReturnType<FnType>>

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
	UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
	'queryKey' | 'queryFn'
>

export type MutationConfig<MutationFnType extends (...args: any) => any> =
	UseMutationOptions<
		ExtractFnReturnType<MutationFnType>,
		AxiosError,
		Parameters<MutationFnType>[0]
	>

/**
 * Proxy Query option to avoid type exeptions
 */
export type UseQueryOptions<
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = Omit<
	NativeUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	'queryKey' | 'queryFn'
>
