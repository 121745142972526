import { jwtDecode } from 'jwt-decode'
import encode from 'jwt-encode'

const jwtKey = 'qA9dsH83GheoeVPBYVQxZp47XvI4TqOC'

export function decodeInstructionsData(text: string) {
	return jwtDecode(text)
}
export function encodeInstructionsData(text: string) {
	return encode(text, jwtKey)
}
