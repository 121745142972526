import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import {
	Button,
	Col,
	Content,
	FormError,
	FormGroup,
	FormLabel,
	InlineMessage,
	Layout,
	Loader,
	Panel,
	PanelBody,
	Row,
	Textfield,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import LockFilledIcon from '@cmpkit/icon/lib/glyph/lock-filled'
import UserIcon from '@cmpkit/icon/lib/glyph/user'

import Container from '@/components/Container'
import { useAuth } from '@/features/auth/authentication'
import { useLoginMutation } from '@/features/auth/mutations'
import { LoginPayload } from '@/generated'
import { yupResolver } from '@hookform/resolvers/yup'

const FormSchema = Yup.object().shape({
	login: Yup.string().required('Login is required'),
	passwd: Yup.string().required('Password is required'),
})

export default function AuthExchange() {
	const navigate = useNavigate()
	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm({
		resolver: yupResolver(FormSchema),
	})

	const { refetchUser } = useAuth()
	const loginMutation = useLoginMutation()
	const onSubmit = (data: LoginPayload) => {
		loginMutation.mutateAsync(data).then(() => {
			refetchUser().then(() => navigate('/'))
		})
	}

	return (
		<Layout row className='relative h-full'>
			<Layout>
				<Content>
					<Container>
						<Row className='h-full'>
							<Col className='h-full flex items-center justify-center'>
								<Panel>
									<PanelBody className='pb-10'>
										<div className='w-80'>
											<h5 className='text-2xl font-black mb-5 text-center'>
												Sign in to your account
											</h5>
											<form
												onSubmit={handleSubmit(onSubmit)}
												className='relative'
											>
												<Blanket
													className='backdrop-blur-lg bg-white/50 dark:bg-black/50 flex items-center justify-center absolute'
													isTinted={loginMutation.isPending}
												>
													<Loader />
												</Blanket>
												{(loginMutation.isSuccess &&
													!loginMutation.data.success) ||
													(loginMutation.isError && (
														<InlineMessage
															variant='danger'
															className='w-full mb-5'
														>
															Login or password is incorrect
														</InlineMessage>
													))}
												<FormGroup>
													<FormLabel>Your login</FormLabel>
													<Textfield
														invalid={!!errors.login}
														{...register('login')}
														placeholder='Enter your login'
														iconBefore={<UserIcon />}
													/>
													{errors.login && (
														<FormError>
															{errors.login.message as string}
														</FormError>
													)}
												</FormGroup>
												<FormGroup>
													<FormLabel>Password</FormLabel>
													<Textfield
														invalid={!!errors.passwd}
														type='password'
														{...register('passwd')}
														placeholder='••••••••'
														iconBefore={<LockFilledIcon />}
													/>
													{errors.passwd && (
														<FormError>
															{errors.passwd.message as string}
														</FormError>
													)}
												</FormGroup>
												<Button
													type='submit'
													variant='primary'
													size='large'
													block
												>
													Login to your account
												</Button>
											</form>
										</div>
									</PanelBody>
								</Panel>
							</Col>
						</Row>
					</Container>
				</Content>
			</Layout>
		</Layout>
	)
}
