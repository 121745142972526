import { uniq } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Button } from '@cmpkit/base'
import { useDisclosure } from '@cmpkit/hooks'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import { Modal, ModalBody, ModalHeader, ModalTransition } from '@cmpkit/modal'

import { useQuiz } from '@/features/instructions/context'

export default function WorkspaceInstructions() {
	const { instructions } = useQuiz()
	const modal = useDisclosure()
	if (instructions?.length) {
		return (
			<>
				<Button
					variant='warn'
					className='font-black'
					iconBefore={<InfoIcon />}
					onClick={() => {
						mixpanel.track('Open instructions modal')
						modal.toggle()
					}}
				>
					Instructions
				</Button>
				<ModalTransition>
					{modal.isOpen && (
						<Modal onClose={modal.close} zIndex={100000} width='900'>
							<ModalHeader
								toggle={modal.close}
								className='cmp-border-bottom bg-accent-3'
							>
								Instructions for all tasks
							</ModalHeader>
							<ModalBody className='p-5'>
								<h3 className='font-bold text-xl'>Instructions</h3>
								{instructions &&
									uniq(instructions).map((item, id) => (
										<div
											className='instructions space-y-2'
											key={id.toString()}
											dangerouslySetInnerHTML={{ __html: item.body }}
										/>
									))}
							</ModalBody>
						</Modal>
					)}
				</ModalTransition>
			</>
		)
	} else {
		return null
	}
}
