import React from 'react'

import {
	QueryObserverResult,
	RefetchOptions,
	UseMutateAsyncFunction,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'

export interface AuthProviderConfig<User = unknown, Error = unknown> {
	key?: string
	loadUser: (data: any) => Promise<User>
	logoutFn: () => Promise<any>
	waitInitial?: boolean
	LoaderComponent?: () => JSX.Element
	ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element
}

export interface AuthContextValue<User = unknown, Error = unknown> {
	user: User | undefined
	logout: UseMutateAsyncFunction<any, any, void, any>
	isLoggingOut: boolean
	refetchUser: (
		options?: RefetchOptions | undefined
	) => Promise<QueryObserverResult<User, Error>>
	error: Error | null
}

export interface AuthProviderProps {
	children: React.ReactNode
}

export function initReactQueryAuth<User = unknown, Error = unknown>(
	config: AuthProviderConfig<User, Error>
) {
	const AuthContext = React.createContext<AuthContextValue<User, Error> | null>(
		null
	)
	AuthContext.displayName = 'AuthContext'

	const {
		loadUser,
		logoutFn,
		waitInitial = true,
		LoaderComponent = () => <div>Loading...</div>,
		ErrorComponent = (error: any) => (
			<div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>
		),
	} = config

	function AuthProvider({ children }: AuthProviderProps): JSX.Element {
		const queryClient = useQueryClient()

		const {
			data: user,
			error,
			status,
			isLoading,
			isSuccess,
			refetch,
		} = useQuery<User, any>({
			queryKey: ['session', 'user'],
			queryFn: loadUser,
			retry: false,
		})
		const logoutMutation = useMutation({
			mutationFn: logoutFn,
			onSuccess: () => {
				queryClient.clear()
			},
		})

		const value = React.useMemo(
			() => ({
				user,
				error,
				refetchUser: refetch,
				logout: logoutMutation.mutateAsync,
				isLoggingOut: logoutMutation.isPending,
			}),
			[
				user,
				error,
				refetch,
				logoutMutation.mutateAsync,
				logoutMutation.isPending,
			]
		)
		if (isSuccess || (error && error?.status !== 404) || !waitInitial) {
			return (
				<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
			)
		}

		if (isLoading) {
			return <LoaderComponent />
		}

		if (error) {
			return <ErrorComponent error={error} />
		}

		return <div>Unhandled status: {status}</div>
	}

	function useAuth() {
		const context = React.useContext(AuthContext)
		if (!context) {
			throw new Error(`useAuth must be used within an AuthProvider`)
		}
		return context
	}

	return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth }
}
