import cx from 'clsx'
import { prop, uniqBy } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import {
	Badge,
	Button,
	Content,
	Header,
	Layout,
	Loader,
	Panel,
	Spinner,
	Textfield
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'
import RefreshIcon from '@cmpkit/icon/lib/glyph/refresh'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'
import Popover from '@cmpkit/popover'

import { useTasksQuery } from '@/features/tasks/queries'
import { TasksItemModel } from '@/generated'
import { generateSalt } from '@/utils/helpers'

export default function TasksDrawer({
	close,
	toggle,
}: {
	close: () => void
	toggle: () => void
}) {
	const { tid } = useParams()
	const navigate = useNavigate()
	const [searchText, setSearchText] = useState('')
	const [taskApp, setTaskApp] = useState<string | null>(null)
	const tasksQuery = useTasksQuery({
		refetchOnMount: 'always',
	})
	const handleTaskClick = async (id: number) => {
		mixpanel.track('Click on task in sidebar')
		await generateSalt(id)
		close()
		navigate(`/app/workspace/${id}`)
	}
	const list = tasksQuery.data?.items?.filter((item) => {
		if (taskApp) {
			return item.taskapp_name === taskApp
		}
		if (searchText === '') {
			return true
		}
		return item?.name?.toLowerCase().includes(searchText.toLowerCase())
	})
	return (
		<Layout className='h-full'>
			<Header className='border-b border-solid border-base px-5 py-2 bg-accent-3 flex items-center space-x-2'>
				<Button size='mini' iconBefore={<CaretLeftIcon />} onClick={toggle} />
				<h4 className='font-bold'>Tasks</h4>
			</Header>
			<Content>
				<div className='w-full flex-col flex relative'>
					<div className='px-5 py-2 border-b border-solid border-base'>
						<div className='flex w-full justify-between space-x-2'>
							<Textfield
								value={searchText}
								className='w-full'
								placeholder='Search...'
								iconAfter={<SearchIcon />}
								onChange={(e) => setSearchText(e.target.value)}
								addonBefore={
									<TaskAppPicker
										value={taskApp}
										onChange={(data) => {
											mixpanel.track('Filter tasks by taskapp', {
												taskapp: data,
											})
											setTaskApp(data)
										}}
										options={
											(uniqBy(
												prop('taskapp_name'),
												tasksQuery.data?.items || []
											)
												.map(prop('taskapp_name'))
												.filter(Boolean) as string[]) || []
										}
									/>
								}
							/>
							<Button
								quite
								onClick={() => {
									mixpanel.track('Refresh tasks list')
									tasksQuery.refetch()
								}}
								iconBefore={
									tasksQuery.isFetching ? <Spinner /> : <RefreshIcon />
								}
							/>
						</div>
					</div>
					<div
						className={cx(
							'w-full flex-col flex divide-y divide-solid divide-base ',
							tasksQuery.isFetching ? 'opacity-40' : ''
						)}
					>
						<Blanket
							className='backdrop-blur-lg bg-white/50 dark:bg-black/50 flex items-center justify-center absolute'
							isTinted={tasksQuery.isLoading}
						>
							<Loader />
						</Blanket>
						{list?.length === 0 && (
							<div className='flex flex-col items-center justify-center h-full p-5 text-center opacity-40'>
								<div className='text-2xl font-bold'>No data</div>
								<div className='text-base '>
									There are no tasks for you or according to your search
								</div>
							</div>
						)}
						{list?.map((task) => {
							return (
								<TaskListItem
									task={task}
									key={task.id}
									isActive={task.id == (tid as any)}
									onClick={() => handleTaskClick(task.id)}
								/>
							)
						})}
					</div>
				</div>
			</Content>
		</Layout>
	)
}

function TaskAppPicker({
	value,
	onChange,
	options,
}: {
	value: string | null
	onChange: (value: string | null) => void
	options: string[]
}) {
	return (
		<Popover
			placement='bottom-start'
			content={
				<Panel className='w-44 overflow-hidden py-2 divide-y divide-base divide-solid'>
					<div
						className='hover:bg-accent-4 px-3 py-2 cursor-pointer truncate overflow-hidden'
						onClick={() => onChange(null)}
					>
						<Badge className='truncate max-w-full'>All</Badge>
					</div>
					{options.map((item) => {
						return (
							<div
								key={item}
								onClick={() => onChange(item)}
								className='hover:bg-accent-4 px-3 py-2 cursor-pointer truncate overflow-hidden'
							>
								<Badge className='truncate max-w-full'>{item}</Badge>
							</div>
						)
					})}
					<div
						onClick={() => onChange('Product matchings')}
						className='hover:bg-accent-4 px-3 py-2 cursor-pointer truncate overflow-hidden'
					>
						<Badge className='truncate'>Priduct matchings</Badge>
					</div>
				</Panel>
			}
		>
			<span className='truncate select-none max-w-24 cursor-pointer hover:text-primary'>
				{value || 'All'}
			</span>
		</Popover>
	)
}
function TaskListItem({
	task,
	isActive,
	onClick,
}: {
	task: TasksItemModel
	isActive: boolean
	onClick: () => void
}) {
	return (
		<div
			key={task.id}
			className={cx(
				'px-5 py-2 flex justify-between cursor-pointer  hover:bg-primary-25 hover:dark:bg-primary-150',
				{
					'bg-primary-25 hover:bg-primary-50 dark:bg-primary-150 hover:dark:bg-primary-175':
						isActive,
				}
			)}
			onClick={onClick}
		>
			<div className='space-y-1'>
				<h5 className='font-semibold'>{task.name}</h5>
				<Badge>{task.taskapp_name}</Badge>
			</div>
			<div className='flex space-x-1'>
				<div className='text-blue'>{task.total}</div>
				<div className='text-green'>{task.available}</div>
				<div className='text-yellow'>{task.skipped}</div>
			</div>
		</div>
	)
}
