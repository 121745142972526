import cx from 'clsx'
import React, { useCallback } from 'react'

import { useAuth } from '@/features/auth/authentication'
import Confirm from '@/lib/confirm'

import { useTimer } from './templates/helpers'

type Props = {
	unitId: number
	defaultTime?: number
}
export default function WorkspaceTimer({ unitId, defaultTime = 900 }: Props) {
	const { logout } = useAuth()
	const onTimeEnd = useCallback(() => {
		Confirm.alert({
			message: 'Time is over. You will be logout from app.',
		}).then(() => {
			logout()
			window.location.reload()
		})
	}, [])
	const timer = useTimer({ onTimeEnd, defaultTime }, unitId)
	return (
		<div
			className={cx(
				'flex items-center font-black text-white px-5 h-full text-lg select-none',
				timer.count < 30
					? 'bg-danger animate-pulse'
					: timer.count < 120
					? 'bg-warn'
					: 'bg-success'
			)}
		>
			{formatSeconds(timer.count)}
		</div>
	)
}
function formatSeconds(num: number) {
	const minutes = padZero(Math.floor(num / 60), 2)
	const seconds = padZero(Math.floor(num % 60), 2)
	return `${minutes}:${seconds}`
}
function padZero(num: number, size: number) {
	let s = String(num)
	while (s.length < size) {
		s = `0${s}`
	}
	return s
}
