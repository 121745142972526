import { TasksModel } from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { useQuery } from '@tanstack/react-query'

export function useTasksQuery<T = TasksModel>(
	options?: UseQueryOptions<TasksModel, any, T>
) {
	return useQuery<TasksModel, any, T>({
		queryKey: ['tasks'],
		queryFn: () =>
			client.work.listTasks({ status: 'new', _c: new Date().getTime() }),
		...(options || {}),
	})
}
