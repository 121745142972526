import { ParamsSerializerOptions } from 'axios'
import qs from 'qs'

import { Api } from '../generated'

const paramsSerializer: ParamsSerializerOptions = {
	serialize: (params: Record<string, any>) =>
		qs.stringify(params, { arrayFormat: 'comma' }),
	indexes: false,
}

const createApiClient = () => {
	return new Api({
		baseURL: '/',
		withCredentials: true,
		paramsSerializer,
	})
}
export const client = createApiClient()
