import { TaskModel } from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { useQuery } from '@tanstack/react-query'

export function useServerTimeQuery<T = any>(
	options?: UseQueryOptions<any, any, T>
) {
	return useQuery<any, any, T>({
		queryKey: ['server_time'],
		queryFn: () => client.util.getServerTime(),
		...(options || {}),
	})
}
export function useWorkspaceQuery<T = TaskModel>(
	taskId: string,
	queryParams: any,
	options?: UseQueryOptions<TaskModel, any, T>
) {
	return useQuery<TaskModel, any, T>({
		queryKey: ['task', taskId],
		queryFn: () =>
			client.work.nextUnits({
				taskId,
				...queryParams,
				salt: localStorage.getItem('salt'),
				_c: new Date().getTime(),
			}),
		...(options || {}),
	})
}
