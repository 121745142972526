import toast from 'react-hot-toast'

import { client } from '@/network/client'

export async function generateSalt(taskId: number) {
	const date = await client.util.getServerTime()
	const salt = taskId * date.month * date.hour + date.day
	localStorage.setItem('salt', String(salt))
	return salt
}
export const parseJson = (data: any) => {
	try {
		return JSON.parse(data)
	} catch (error) {
		return {}
	}
}
export const downloadFile = (url: string) => {
	return new Promise((resolve, reject) => {
		const hiddenIFrameId = 'hiddenDownloader'
		let iframe: any = document.getElementById(hiddenIFrameId)
		if (iframe === null) {
			iframe = document.createElement('iframe')
			iframe.id = hiddenIFrameId
			iframe.style.display = 'none'
		}

		iframe.onload = function () {
			setTimeout(() => {
				resolve(true)
				this.parentNode.removeChild(this)
			}, 1000)
		}
		iframe.onerror = function () {
			setTimeout(() => {
				reject()
				this.parentNode.removeChild(this)
			}, 1000)
		}
		iframe.src = url
		document.body.appendChild(iframe)
	})
}
/**
 * Blink element by id
 *
 * @export
 * @param {string} id
 */
export function blink(id: string) {
	const el = document.getElementById(id)
	if (!el) return
	el.classList.add('hightlighted')
	setTimeout(() => {
		el.classList.remove('hightlighted')
	}, 700)
}

/**
 * Copy value to clipboard
 *
 * @param {string} text
 */
export const unsafeCopyToClipboard = (text: string) => {
	const currentSelection = document.getSelection()
	if (
		currentSelection?.anchorNode &&
		currentSelection.anchorOffset !==
			(currentSelection.anchorNode as any)?.length &&
		currentSelection.anchorOffset !== (currentSelection as any).extentOffset
	) {
		document.execCommand('copy')
		toast.success('Value partially copied to clipboard', {
			duration: 2000,
			id: 'copy',
		})

		return
	}

	const node = document.createElement('div')
	node.style.fontSize = '0.00000001px'
	node.textContent = text
	document.body.appendChild(node)
	/*eslint-disabled*/
	try {
		const range = document.createRange()
		range.selectNodeContents(node)
		window.getSelection()?.removeAllRanges()
		window.getSelection()?.addRange(range)
		document.execCommand('copy')
		window.getSelection()?.removeAllRanges()
		toast.success('Value copied to clipboard', {
			duration: 2000,
			id: 'copy',
		})
	} catch (e) {
		console.error(e)
		toast.error('Error on copy', { duration: 2000, id: 'copy' })
	} finally {
		document.body.removeChild(node)
	}
}
