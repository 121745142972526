import { UpdateProfilePayload } from '@/generated'
import { client } from '@/network/client'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

export function useSaveProfileMutation(
	options?: UseMutationOptions<any, any, UpdateProfilePayload>
) {
	return useMutation<any, any, UpdateProfilePayload>({
		mutationFn: (body: UpdateProfilePayload) => client.base.updateProfile(body),
		...(options || {}),
	})
}
export function useUpdateUserTiersMutation(options?: UseMutationOptions<any>) {
	return useMutation<any, any, any>({
		mutationFn: (body: any) => client.base.updateMyTiers(body),
		...(options || {}),
	})
}
