import { first, isObject, pipe, prop, split } from 'lodash/fp'
import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Get object with meta data from unit request
 * @param resp - unit request object
 * @returns - object with meta data
 */
export const getCustomerMeta = (resp: any): any => {
	return {
		product_link: resp.product_my_url,
		urlInfo: getLocation(resp.product_my_url || '') || {},
		title: resp.product_title,
		sku: resp.product_sku,
		vendor: resp.product_vendor,
		mpn: resp.product_mpn,
		upc: resp.product_upc,
		price: resp.product_my_price,
		urlOption: resp.product_my_url_option
			? isObject(resp.product_my_url_option)
				? resp.product_my_url_option?.opt
				: resp.product_my_url_option
			: null,
	}
}

/**
 * Get object with meta data from unit request
 * @param resp - unit request object
 * @returns - object with meta data
 */
export const getCompetitiorMeta = (resp: any) => {
	return {
		product_link: resp.url,
		urlInfo: getLocation(resp.url),
		product_title: resp.product_title,
		title: resp.title,
		sku: resp.sku,
		mpn: resp.mpn,
		upc: resp.upc,
		price: resp.price,
		urlOption: resp.url_option
			? isObject(resp.url_option)
				? resp.url_option?.opt
				: resp.url_option
			: null,
	}
}

/**
 *  Open new windows with product base on unit Request
 * @param unitRequest - unit request object
 */
export function openBoth(unitRequest: any) {
	const customer = getCustomerMeta(unitRequest)
	const competitor = getCompetitiorMeta(unitRequest)
	popupwindow(customer.product_link, 'customer', screen.width / 2, 600, 0, 0)
	popupwindow(
		competitor.product_link,
		'competitor',
		screen.width / 2,
		600,
		screen.width / 2,
		0
	)
}

/**
 * Open new window
 * @param url - url to open
 * @param title - title of window
 * @param w - width of window
 * @param h - height of window
 * @param left - left position of window
 * @param top - top position of window
 */
export const popupwindow = (
	url: string,
	title: string,
	w: number,
	h: number,
	left: number,
	top: number
) => {
	return window.open(
		url,
		title,
		'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
			w +
			', height=' +
			h +
			', top=' +
			top +
			', left=' +
			left
	)
}

/**
 * Get parsed url propertires
 * @param href - url to parse
 * @returns
 */
export function getLocation(href: string) {
	const match = href?.match(
		// eslint-disable-next-line
		/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
	)
	return (
		match && {
			href: href,
			protocol: match[1],
			host: match[2],
			hostname: match[3],
			port: match[4],
			pathname: match[5],
			search: match[6],
			hash: match[7],
		}
	)
}

export const getProductUrl = prop('product_my_url')
export const getCompetitorName = prop('competitor_name')
export const getCampaignName = pipe([prop('title'), split('vs.'), first])
export const getProductTitle = prop('product_title')
export const getCompetitorUrl = prop('competitor_url')
export const getCompetitorProductUrl = prop('url')
export const getCompetitorProductUrlOption = prop('url_option')
export const isOptionEnabled = prop('competitor_enable_url_option')
export const getCompetitorUrlOption = (unitRequest: any): string =>
	unitRequest.url_option
		? isObject(unitRequest.url_option)
			? unitRequest.url_option?.opt
			: unitRequest.url_option
		: null
/* eslint-disable */
let urlRegex = /^http[s]?:\/\/[a-z0-9\-\.]+\.[\w]+[\:\d]*\/?.*?/i
/* eslint-enable */

/**
 * Check if value is url
 * @param value - value to check
 * @returns - true if value is url
 */
export const isUrl = (value: string) => value && urlRegex.test(value)

/**
 * Check if value is valid url
 * @param value - value to check
 * @param data - unit request object
 * @returns - true if value is valid url
 */
export const isValidUrl = (value: string, ruleUrl: any): boolean => {
	const loc = getLocation(ruleUrl)
	const isValidByLocalPattern = isUrl(value)
	const includesHost = ruleUrl
		? loc
			? value.includes(loc.hostname)
			: false
		: true
	return Boolean(isValidByLocalPattern) && includesHost
}

/**
 * Check if value option is valid
 * @param value_option - value option to check
 * @returns - true if value option is valid
 */
export const isValidValueOption = (value_option: string): boolean => {
	// Preventing matches to fill value_option with url from value
	return !isUrl(value_option)
}

export function useTimer({ onTimeEnd, defaultTime = 900 }: any, deps: any) {
	const timer = useRef<any>(null)
	const [count, setCount] = useState(defaultTime)
	useEffect(() => {
		start()
		return () => {
			setCount(defaultTime)
			clearInterval(timer.current)
		}
	}, [deps])
	const start = useCallback(() => {
		timer.current = setInterval(() => {
			setCount((count: number) => {
				const next = count - 1
				if (next === 0) {
					onTimeEnd()
					clearInterval(timer.current)
					return 0
				} else {
					return next
				}
			})
		}, 1000)
	}, [onTimeEnd])

	const reset = useCallback(() => {
		clearInterval(timer.current)
		setCount(0)
	}, [setCount])

	return {
		count,
		start,
		reset,
	}
}
/**
 * Open link in google search
 * @param domain - domain name
 * @param title - title of product
 */
export const openLinkInGoole = (domain: string, title: string): void => {
	window.open(
		`https://www.google.com/search?q=site:${domain} ${sanitizeTitle(
			title || ''
		)}`,
		'_blank'
	)
}
/**
 * Open link in bing search
 * @param domain - domain name
 * @param title - title of product
 */
export const openLinkInBing = (domain: string, title: string): void => {
	window.open(
		`https://www.bing.com/search?q=site:${domain} ${sanitizeTitle(
			title || ''
		)}`,
		'_blank'
	)
}

export const handleOpenSingleInTab = (url: string): void => {
	window.open(url, '_blank')
}
export function sanitizeTitle(title = '') {
	/* eslint-disable */
	//.replace(/[^\w\s]/gi, '')
	return encodeURIComponent(
		title.replace('&', ' ').replace(/[\&\#\"\'\^\$\%\*\@\(\)\!]+/gi, ' ')
	)
	/* eslint-enable */
}
