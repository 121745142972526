import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { useQuery } from '@tanstack/react-query'

export function useInstructionsQuery<T = string>(
	queryParams: any,
	options?: UseQueryOptions<any>
) {
	return useQuery<string, any, T>({
		queryKey: ['instructions', queryParams],
		queryFn: () =>
			client.instructions.getInstructions({
				...queryParams,
				_c: new Date().getTime(),
			}),
		...(options || {}),
	})
}

export function useInstructionsAnswersQuery<T = string>(
	queryParams: any,
	options?: UseQueryOptions<any>
) {
	return useQuery<string, any, T>({
		queryKey: ['instructions', queryParams, 'answers'],
		queryFn: () =>
			client.instructions.getAnswers({
				...queryParams,
				_c: new Date().getTime(),
			}),
		...(options || {}),
	})
}
