import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import {
	Avatar,
	Button,
	Footer,
	Header,
	Layout,
	MenuItem,
	MenuList,
	Panel,
} from '@cmpkit/base'
import Drawer from '@cmpkit/drawer'
import { useDisclosure } from '@cmpkit/hooks'
import DashboardIcon from '@cmpkit/icon/lib/glyph/dashboard'
import FileIcon from '@cmpkit/icon/lib/glyph/file'
import LogOutIcon from '@cmpkit/icon/lib/glyph/log-out'
import MoonIcon from '@cmpkit/icon/lib/glyph/moon'
import RuleIcon from '@cmpkit/icon/lib/glyph/rule'
import SunIcon from '@cmpkit/icon/lib/glyph/sun'
import UserIcon from '@cmpkit/icon/lib/glyph/user'
import Popover from '@cmpkit/popover'

import ErrorBoundary from '@/components/ErrorBoundary'
import { useAuth } from '@/features/auth/authentication'
import { useTheme } from '@/hooks/useTheme'
import TasksDrawer from '@/scenes/Tasks/TasksDrawer'

export const ApplicationLayout = () => {
	const navigate = useNavigate()
	const drawer = useDisclosure(false)
	const [theme, toggleTheme]: any = useTheme()
	const { user } = useAuth()
	const username = user?.email || ''
	useEffect(() => {
		if (user?.email && user?.id) {
			mixpanel.identify(String(user.id))
			mixpanel.people.set({ $email: user?.email, $name: user?.login })
		}
	}, [user])
	return (
		<Layout className='h-full'>
			<Header className='bg-accent-1 border-b border-solid border-base px-5 py-1 flex justify-between'>
				<div className='flex items-center'>
					<h3
						className='uppercase font-black cursor-pointer'
						onClick={() => navigate('/app')}
					>
						Workbench
					</h3>
				</div>
				<div className='flex items-center divide-x divide-base divide-solid h-full'>
					<div className='px-2 flex items-center'>
						<Button
							size='mini'
							active={drawer.isOpen}
							onClick={() => {
								mixpanel.track('Open tasks sidebar')
								drawer.toggle()
							}}
							iconBefore={<DashboardIcon />}
						/>
					</div>
					<div className='px-2 flex items-center'>
						<Button
							size='mini'
							onClick={() => {
								mixpanel.track('Switch theme', {
									theme: theme === 'dark' ? 'light' : 'dark',
								})
								toggleTheme() //
							}}
							iconBefore={theme !== 'dark' ? <MoonIcon /> : <SunIcon />}
						/>
					</div>
					<div className='pl-2'>
						<Popover content={<ProfileDropdown />}>
							<div className='text-muted hover:text-primary cursor-pointer items-center flex'>
								<span className='mr-1  text-xs'>{username}</span>
								<Avatar name={username} className='rounded-sm' size='small' />
							</div>
						</Popover>
					</div>
				</div>
			</Header>
			<Drawer
				className='cmp-border page-drawer'
				orient='left'
				isOpen={drawer.isOpen}
				onClose={drawer.close}
			>
				<TasksDrawer close={drawer.close} toggle={drawer.toggle} />
			</Drawer>
			<Layout className='overflow-hidden'>
				<ErrorBoundary>
					<Outlet />
				</ErrorBoundary>
			</Layout>
			<Footer className='border-t border-solid border-base px-5 py-1 text-2xs text-muted'>
				{`${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_BUILD}`}
			</Footer>
		</Layout>
	)
}
export default ApplicationLayout

function ProfileDropdown() {
	const { user, logout } = useAuth()
	const openReadme = () => {
		const frameId = 'readme_frame'
		const iframe: any = document.getElementById(frameId)
		const body = iframe.contentWindow.document.body
		const path = '/docs/general_info'
		const password = 'Vfnxbyu2019'
		body.innerHTML = `
			<form id="frm" method="POST" action="https://wb.readme.io/password" target="_blank">
        <input value="${path}" name="redirect" />
        <input value="${password}" name="password" />
      </form>`
		iframe.contentWindow.document.getElementById('frm').submit()
	}
	return (
		<Panel className='w-60  overflow-hidden border border-base border-solid'>
			<div className='px-5 py-2 bg-accent-3 border-b border-base border-solid'>
				<span className='text-muted font-semibold'>{user?.email || ''}</span>
			</div>
			<iframe
				id='readme_frame'
				src=''
				style={{ opacity: 0, width: 1, height: 1 }}
			/>
			<MenuList className='py-2'>
				<NavLink
					to='/app/tiers'
					className='cursor-pointer menu-item no-underline text-foreground'
				>
					<RuleIcon />
					<span>Tiers</span>
				</NavLink>
				<NavLink
					to='/app/profile'
					className='cursor-pointer menu-item no-underline text-foreground'
				>
					<UserIcon />
					<span>Profile</span>
				</NavLink>

				<MenuItem onClick={openReadme} className='cursor-pointer'>
					<FileIcon />
					<span>Readme</span>
				</MenuItem>
				<MenuItem onClick={() => logout()} className='cursor-pointer'>
					<LogOutIcon />
					<span>Logout</span>
				</MenuItem>
			</MenuList>
		</Panel>
	)
}
