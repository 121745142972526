import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Button } from '@cmpkit/base'

import logo from '@/assets/images/pngwing.png'

type WorkspaceBingButton = {
	onClick: () => void
	disabled?: boolean
}
export default function WorkspaceBingButton({
	onClick,
	disabled,
}: WorkspaceBingButton) {
	return (
		<Button
			className='flex flex-1'
			disabled={disabled}
			size='large'
			iconBefore={<img className='mr-2' height={20} width={20} src={logo} />}
			onClick={() => {
				mixpanel.track('Bing button clicked')
				onClick()
			}}
		>
			Open in Bing
		</Button>
	)
}
