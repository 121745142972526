import cx from 'clsx'
import React from 'react'

import { Button, Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import Logger from '@/lib/logger'
import intl from '@/locale'

type Props = {
	reloadOnChunkMissing?: boolean
	children?: any
}
type State = {
	error: Error | null | undefined
	showDetails: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
	state: State = {
		error: null,
		showDetails: false,
	}
	static getDerivedStateFromError(error: Error) {
		return { error: error }
	}
	componentDidCatch(error: Error) {
		if (
			this.props.reloadOnChunkMissing &&
			error.message &&
			error.message.match(/chunk/)
		) {
			// If the editor bundle fails to load then reload the entire window. This
			// can happen if a deploy happens between the user loading the initial JS
			// bundle and the async-loaded editor JS bundle as the hash will change.
			window.location.reload()
			return
		}

		Logger.error('ErrorBoundary', error)
	}

	handleReload = () => {
		window.location.reload()
	}

	handleShowDetails = () => {
		this.setState({
			showDetails: true,
		})
	}

	handleReportBug = () => {
		window.open('/')
	}

	render() {
		if (this.state.error) {
			const error = this.state.error
			//const isReported = !!env.SENTRY_DSN
			const isChunkError = this.state?.error?.message?.match?.(/chunk/)

			if (isChunkError) {
				return (
					<Result
						className={cx('m-auto')}
						icon={<AlertIcon width={80} height={80} />}
						title={intl
							.get('app.module_failed.title')
							.defaultMessage('Module failed to load')}
						subtitle={intl
							.get('app.module_failed.text')
							.defaultMessage(
								'Sorry, part of the application failed to load. This may be because it was updated since you opened the tab or because of a failed network request. Please try reloading.'
							)}
					/>
				)
			}

			return (
				<Result
					className={cx('m-auto')}
					icon={<AlertIcon width={80} height={80} />}
					title={intl
						.get('app.unexpected_error.title')
						.defaultMessage('Something Unexpected Happened')}
					subtitle={intl
						.get('app.unexpected_error.text')
						.defaultMessage(
							'Sorry, an unrecoverable error occurred . Please try reloading the page, it may have been a temporary glitch.'
						)}
				>
					<div className='flex flex-col space-y-2'>
						{this.state.showDetails && <pre>{error.toString()}</pre>}
						<div>
							<Button onClick={this.handleReload}>
								{intl.get('app.reload').defaultMessage('Reload')}
							</Button>{' '}
							{this.state.showDetails ? null : (
								<Button onClick={this.handleShowDetails}>
									{intl.get('app.show_detail').defaultMessage('Show Detail')}
								</Button>
							)}
						</div>
					</div>
				</Result>
			)
		}
		return this.props.children
	}
}
export default ErrorBoundary
