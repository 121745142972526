import { first, lt, pipe, prop, size } from 'lodash/fp'
import React from 'react'
import { Navigate } from 'react-router'

import { Loader, Result } from '@cmpkit/base'
import ClockIcon from '@cmpkit/icon/lib/glyph/clock'

import { useTasksQuery } from '@/features/tasks/queries'
import { useServerTimeQuery } from '@/features/workspace/queries'

export default function WorkspaceIndexPage() {
	const tasksQuery = useTasksQuery<any>({
		select: (data) => (hasItems(data) ? selectFirstTask(data)?.id : null),
		refetchOnMount: 'always',
	})
	const serverSalt = useServerTimeQuery({
		enabled: tasksQuery.data !== null,
		refetchOnMount: 'always',
		select: (date) => tasksQuery.data * date.month * date.hour + date.day,
	})
	if (tasksQuery.isLoading || serverSalt.isFetching) {
		return (
			<div className='h-full w-full flex flex-col items-center justify-center '>
				<Loader />
				<p className='text-muted mt-2 text-xs'>Looking for a tasks...</p>
			</div>
		)
	}
	if (tasksQuery.data !== null) {
		const taskId = tasksQuery.data
		localStorage.setItem('salt', String(serverSalt.data))
		return <Navigate to={`/app/workspace/${taskId}`} />
	}
	return (
		<Result
			className={'m-auto'}
			icon={<ClockIcon width={80} height={80} />}
			title={'No tasks for your account yet'}
			subtitle='There is nothing to do here, please come back later'
		/>
	)
}
const selectFirstTask = pipe([prop('items'), first])
const hasItems = pipe([prop('items'), size, lt(0)])
