import React from 'react'
import { useForm } from 'react-hook-form'

import { Button, FormGroup, FormLabel, Input } from '@cmpkit/base'

export default function ProfileForm({ onSubmit, defaultValues }: any) {
	const { register, handleSubmit, reset } = useForm({
		defaultValues,
	})
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormGroup>
				<FormLabel>Login</FormLabel>
				<Input {...register('login')} readOnly />
			</FormGroup>
			<FormGroup>
				<FormLabel>Email</FormLabel>
				<Input {...register('email', { required: true })} type='email' />
			</FormGroup>
			<FormGroup>
				<FormLabel>Password</FormLabel>
				<Input {...register('password')} type='password' />
			</FormGroup>
			<FormGroup>
				<FormLabel>Confirm password</FormLabel>
				<Input {...register('password2')} type='password' />
			</FormGroup>
			<div className='flex items-center space-x-2'>
				<Button className='ml-auto' onClick={() => reset()}>
					Reset
				</Button>
				<Button type='submit' variant='primary' className='ml-auto'>
					Save
				</Button>
			</div>
		</form>
	)
}
