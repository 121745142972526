import { any, prop } from 'lodash/fp'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import {
	Button,
	Col,
	Content,
	FormGroup,
	FormLabel,
	Header,
	Layout,
	Loader,
	Panel,
	PanelBody,
	PanelHeader,
	PanelTitle,
	Row,
} from '@cmpkit/base'
import Blanket from '@cmpkit/blanket'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'

import { useUpdateUserTiersMutation } from '@/features/account/mutations'
import { useTiersQuery, useUserTiersQuery } from '@/features/account/queries'
import { TierModel } from '@/generated'

export default function ProfileTiers() {
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const updateTiersMutation = useUpdateUserTiersMutation({
		onSettled() {
			queryClient.invalidateQueries({
				queryKey: ['user_tiers'],
			})
		},
	})
	const tiersQuery = useTiersQuery()
	const userTiersQuery = useUserTiersQuery()
	const isLoading =
		any(prop('isLoading'), [tiersQuery, userTiersQuery]) &&
		updateTiersMutation.isPending
	return (
		<Layout className='w-full'>
			<Header className='border-b border-solid border-base px-5 py-2 bg-accent-3 flex items-center space-x-2'>
				<Button
					size='mini'
					iconBefore={<CaretLeftIcon />}
					onClick={() => navigate('/app')}
				/>
				<h5 className='font-bold'>Account settings - Tiers</h5>
			</Header>
			<Content className='w-full p-5'>
				<Row>
					<Col>
						<TierPanel
							title={'This month tier'}
							isLoading={isLoading}
							tier={userTiersQuery.data?.current_tier}
							options={tiersQuery.data?.items}
							onSubmit={({ tier_id }: any) =>
								updateTiersMutation.mutate({
									tier_type: 'current',
									tier_id: Number(tier_id),
								})
							}
						/>
					</Col>
					<Col>
						<TierPanel
							title={'Next month tier'}
							isLoading={isLoading}
							tier={userTiersQuery.data?.next_month_tier}
							options={tiersQuery.data?.items}
							onSubmit={({ tier_id }: any) =>
								updateTiersMutation.mutate({
									tier_type: 'next_month',
									tier_id: Number(tier_id),
								})
							}
						/>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}
function TierPanel({ title, tier, options, onSubmit, isLoading }: any) {
	const { handleSubmit, register } = useForm({
		defaultValues: {
			tier_id: tier?.id,
		},
	})
	const _onSubmit = (data: any) => onSubmit(data)
	return (
		<form onSubmit={handleSubmit(_onSubmit)} className='relative'>
			<Panel className='relative'>
				<Blanket
					className='backdrop-blur-lg bg-white/50 dark:bg-black/50 flex items-center justify-center absolute'
					isTinted={isLoading}
				>
					<Loader />
				</Blanket>
				<PanelHeader>
					<PanelTitle>{title}</PanelTitle>
				</PanelHeader>
				<PanelBody>
					<div className='space-y-2'>
						<div>
							<div className='text-muted text-xs'>Tier name</div>
							<div className='font-bold text-lg'>{tier?.name || '-'}</div>
						</div>
						<div>
							<div className='text-muted text-xs'>Tier units amount</div>
							<div className='font-bold text-lg'>
								{tier?.units_amount || '-'}
							</div>
						</div>
						<div>
							<div className='text-muted text-xs'>Tier cost</div>
							<div className='font-bold text-lg'>{tier?.cost || '-'}</div>
						</div>
					</div>
					<FormGroup>
						<FormLabel>Choose month tier</FormLabel>
						<select
							className='cmp-input'
							{...register('tier_id', { required: true })}
						>
							{options?.map((option: TierModel) => (
								<option
									key={option.id}
									value={option.id}
								>{`Units amount: ${option.units_amount} Name: (${option.name})`}</option>
							))}
						</select>
					</FormGroup>
					<Button variant='success' size='large' block type='submit'>
						Save settings
					</Button>
				</PanelBody>
			</Panel>
		</form>
	)
}
