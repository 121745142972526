import React from 'react'
import { useForm } from 'react-hook-form'

import { Button, FormError, FormGroup, FormLabel, Input } from '@cmpkit/base'
import UploadCloudIcon from '@cmpkit/icon/lib/glyph/upload-cloud'
import XCircleIcon from '@cmpkit/icon/lib/glyph/x-circle'

export default function FormWrong({
	onSubmit,
	onNotFoundClick,
	resolver,
}: any) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver,
	})
	const _onSubmit = handleSubmit(onSubmit)

	return (
		<form onSubmit={_onSubmit} className='py-5'>
			<FormGroup>
				<FormLabel>{'Correct URL'}</FormLabel>
				<Input
					autoComplete='off'
					autoCorrect='off'
					{...register('correctUrl', { required: 'URL is requred' })}
					placeholder={'Paste competiotor product url here...'}
				/>
				{errors?.correctUrl?.message && (
					<FormError>{errors?.correctUrl?.message as any}</FormError>
				)}
			</FormGroup>
			<FormGroup>
				<FormLabel>{'Option'}</FormLabel>
				<Input
					autoComplete='off'
					autoCorrect='off'
					{...register('urlOption')}
					placeholder={'Paste product option here...'}
				/>
				{errors?.urlOption?.message && (
					<FormError>{errors?.urlOption?.message as any}</FormError>
				)}
			</FormGroup>
			<div className='flex space-x-2'>
				<Button
					type='submit'
					variant='success'
					iconBefore={<UploadCloudIcon />}
					onClick={_onSubmit}
				>
					Submit
				</Button>
				<Button
					variant='danger'
					iconBefore={<XCircleIcon />}
					onClick={onNotFoundClick}
				>
					Not found
				</Button>
			</div>
		</form>
	)
}
