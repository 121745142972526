import React, { Component } from 'react'
import { createRoot } from 'react-dom/client'

import { Button, Checkbox, FormGroup } from '@cmpkit/base'
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTransition,
} from '@cmpkit/modal'

type Props = {
	timeout?: number
	title: string
	message?: string
	dialogWidth?: string
	buttons?: any[]
	children?: any
	closeOnClickOutside?: boolean
	closeOnEscape?: boolean
	keyCodeForClose?: number[]
	willUnmount: any
	afterClose: any
	onClickOutside: any
	onKeypressEscape: any
	onkeyPress: any
	modalClassName: string
	onClose?: any
	checkbox?: {
		id: string
		label: string
	}
}
export class ConfirmAlert extends Component<Props, any> {
	constructor(props: any) {
		super(props)
		this.state = {}
	}
	static defaultProps = {
		title: 'Are you sure?',

		buttons: [
			{
				label: 'OK',
				onClick: () => null,
				variant: 'primary',
			},
			{
				label: 'Cancel',
				onClick: () => null,
				variant: 'base',
			},
		],
		childrenElement: () => null,
		dialogWidth: '500',
		closeOnClickOutside: true,
		closeOnEscape: true,
		keyCodeForClose: [],
		willUnmount: () => null,
		afterClose: () => null,
		onClickOutside: () => null,
		onKeypressEscape: () => null,
	}

	handleClickButton = (button: any) => {
		if (button.onClick) button.onClick(this.state)
		this.close()
	}

	close = () => {
		removeBodyClass()
		removeElementReconfirm(this.props)
		this.props.onClose?.()
	}

	keyboard = (event: any) => {
		const { closeOnEscape, onKeypressEscape, onkeyPress, keyCodeForClose } =
			this.props
		const keyCode = event.keyCode
		const isKeyCodeEscape = keyCode === 27

		if (keyCodeForClose?.includes(keyCode)) {
			this.close()
		}

		if (closeOnEscape && isKeyCodeEscape) {
			onKeypressEscape(event)
			this.close()
		}

		if (onkeyPress) {
			onkeyPress()
		}
	}

	componentDidMount = () => {
		this.props.timeout &&
			setTimeout(() => {
				this.close()
			}, this.props.timeout)
		document.addEventListener('keydown', this.keyboard, false)
	}

	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.keyboard, false)
		this.props.willUnmount()
	}
	render() {
		const { title, message, buttons, children, checkbox } = this.props
		const isOpen = true
		return (
			<ModalTransition>
				{isOpen && (
					<Modal
						zIndex={1850}
						onClose={() => this.close()}
						size='small'
						//width={dialogWidth}
					>
						<ModalHeader className='pb-0'>
							{title && (
								<h3 className='font-bold text-primary text-xl'>{title}</h3>
							)}
						</ModalHeader>
						<ModalBody>
							{message}
							{children}
						</ModalBody>
						<ModalFooter>
							{checkbox && (
								<FormGroup className='flex mr-auto mb-0'>
									<Checkbox
										checked={this.state[checkbox.id]}
										onChange={({ target: { checked } }) =>
											this.setState({ [checkbox.id]: checked })
										}
									>
										{checkbox.label}
									</Checkbox>
								</FormGroup>
							)}
							{buttons?.map((button: any, i) => (
								<Button
									key={i}
									size='large'
									autoFocus={button.variant === 'primary'}
									{...button}
									onClick={() => {
										return this.handleClickButton(button)
									}}
								>
									{button.label}
								</Button>
							))}
						</ModalFooter>
					</Modal>
				)}
			</ModalTransition>
		)
	}
}

let root: any = null
const targetId = 'react-confirm-alert'

function createElementReconfirm(properties: any) {
	let divTarget = document.getElementById(properties.targetId || targetId)

	if (properties.targetId && !divTarget) {
		console.error(
			'React Confirm Alert:',
			`Can not get element id (#${properties.targetId})`
		) //eslint-disable-line
	}

	if (divTarget) {
		root = createRoot(divTarget)
		root.render(<ConfirmAlert {...properties} />)
	} else {
		divTarget = document.createElement('div')
		divTarget.id = targetId
		document.body.appendChild(divTarget)
		root = createRoot(divTarget)
		root.render(<ConfirmAlert {...properties} />)
	}
}

function removeElementReconfirm(properties: any) {
	const target = document.getElementById(properties.targetId || targetId)
	if (target) {
		root.unmount(target)
	}
}

function addBodyClass() {
	document.body.classList.add('react-confirm-alert-body-element')
}

function removeBodyClass() {
	document.body.classList.remove('react-confirm-alert-body-element')
}

export function confirmAlert(properties: any) {
	addBodyClass()
	createElementReconfirm(properties)
}

type ExtendedProps = {
	okText?: string
	cancelText?: string
}
export default {
	confirm: function (options: Partial<Props> & ExtendedProps) {
		return new Promise<boolean>((resolve) => {
			const buttons = [
				{
					label: options.cancelText || 'Cancel',
					onClick: () => resolve(false),
				},
				{
					label: options.okText || 'OK',
					onClick: () => resolve(true),
					variant: 'primary',
				},
			]
			return confirmAlert({
				...options,
				onClose: () => resolve(false),
				buttons,
			})
		})
	},
	action: function (options: Partial<Props>) {
		return new Promise<{ action: string }>((resolve) => {
			return confirmAlert({
				...options,
				onClose: () => resolve({ action: 'close' }),
				buttons: options?.buttons?.map((button) => ({
					...button,
					onClick: () => resolve({ action: button.action }),
				})),
			})
		})
	},
	alert: function (options: Partial<Props> & ExtendedProps) {
		return new Promise<boolean>((resolve) => {
			const buttons = [
				{
					label: options.okText || 'OK',
					onClick: () => resolve(true),
					variant: 'primary',
				},
			]
			return confirmAlert({
				...options,
				onClose: () => resolve(false),
				buttons,
			})
		})
	},
	blink: function (options: Partial<Props> & ExtendedProps) {
		return confirmAlert({
			...options,
			timeout: 2000,
			buttons: [],
		})
	},
}
