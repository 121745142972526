import { create } from 'zustand'

/**
 * Zustand store
 */
interface WorkspaceState {
	list: any
	addToLog(key: string, value: any): void
	clearLog(): void
}
export const useAnswersLogStore = create<WorkspaceState>((set) => ({
	list: {},
	addToLog: (key, value) =>
		set((state) => ({
			...state,
			list: { ...state.list, [key]: value },
		})),
	clearLog: () =>
		set((state) => ({
			...state,
			list: {},
		})),
}))
