import '@/assets/styles/index.css'
import '@/lib/sentry'

import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from '@/containers/App'

const getTheme = () => {
	try {
		return JSON.parse(localStorage.getItem('theme') as string)
	} catch (error) {
		return ''
	}
}

if (document && document.body) {
	document.body.setAttribute('data-theme', getTheme())
}
process.env.REACT_APP_MIXPANEL &&
	mixpanel.init(process.env.REACT_APP_MIXPANEL, {
		//debug: true,
		track_pageview: true,
		persistence: 'localStorage',
	})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
