import { isNil, prop, trim } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import Mousetrap from 'mousetrap'
import React, { useCallback, useEffect, useState } from 'react'

import { Col, Content, InlineMessage, Layout, Row, Sider } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import ArrowSquaredDownRightIcon from '@cmpkit/icon/lib/glyph/arrow-squared-down-right'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'

import { WorkspaceActionButton } from '@/components/WorkspaceActionButton'
import { blink } from '@/utils/helpers'

import { WorkspaceTemplateProps } from '../../types'
import WorkspaceBingButton from '../../WorkspaceBingButton'
import WorkspaceGoogleButton from '../../WorkspaceGoogleButton'
import WorkspaceMeta from '../../WorkspaceMeta'
import WorkspaceQueue from '../../WorkspaceQueue'
import WorkspaceTaskTitle from '../../WorkspaceTaskTitle'
import {
	isValidUrl,
	openBoth,
	openLinkInBing,
	openLinkInGoole,
} from '../helpers'
import FormWrong from './FormWrong'

export default function QATemplate({
	task,
	queue,
	unit,
	unitRequest,
	skip,
	sendAnswer,
	isQueueOpen,
}: WorkspaceTemplateProps) {
	/**
	 * State for answer form (same/different)
	 */
	const [answerForm, setAnswerForm] = useState<null | string>(null)

	/**
	 * Open both links in new tabs
	 */
	const handleOpenBoth = useCallback(() => {
		mixpanel.track('Hot key pressed', {
			taskapp: 'QA',
			hotkey: 'Alt+W',
		})
		openBoth(unitRequest)
	}, [unit.id])

	/**
	 * Bind keyboard shortcut for opening both links
	 */
	useEffect(() => {
		Mousetrap.bind(['alt+w'], handleOpenBoth)
		blink('workspace_task_container')
		return () => {
			Mousetrap.unbind(['alt+w'])
			setAnswerForm(null)
		}
	}, [unit.id])

	const comment = prop('comments', task)

	/**
	 * Handle answer form submit as same
	 */
	const handleCorrectClick = () => {
		mixpanel.track('Answer unit as correct', {
			taskapp: 'QA',
		})
		sendAnswer({
			is_correct: true,
			new_matching_found: false,
			url: null,
			url_option: null,
			not_found_flag: false,
		})
	}

	/**
	 * Handle answer form submit as different
	 * @param params - object with params for validation
	 */
	const handleAnswerFound = ({ correctUrl, urlOption }: any): void => {
		mixpanel.track('Send unit answer as incorrect and found', {
			taskapp: 'QA',
		})
		sendAnswer({
			is_correct: false,
			new_matching_found: true,
			url: trim(correctUrl),
			url_option: urlOption,
			not_found_flag: false,
		})
	}

	/**
	 * Handle answer form submit as not found
	 */
	const handleAnswerNotFound = (): void => {
		mixpanel.track('Send unit answer as incorrect and not found', {
			taskapp: 'QA',
		})
		sendAnswer({
			is_correct: false,
			new_matching_found: false,
			url: null,
			url_option: null,
			not_found_flag: true,
		})
	}

	/**
	 * Handle answer form submit as wrong url (with validation)
	 * @param param - object with params for validation
	 * @returns - object with errors
	 */
	const validateFormWrong = (values: any) => {
		const { correctUrl } = values

		const errors: any = {}
		const oldUrl = getOldUrl()
		//const oldUrlOption = getOldUrlOption()
		if (!correctUrl) {
			errors['correctUrl'] = {
				message: 'URL is required',
			}
		} else if (!isValidUrl(correctUrl, oldUrl)) {
			errors['correctUrl'] = {
				message: 'URL should be on the same domain as the competotor URL',
			}
		}
		return {
			values,
			errors,
		}
	}
	/**
	 * Get old response url
	 */
	const getOldUrl = () => unitRequest?.old_response?.value

	/**
	 * Get old response url option
	 */
	const getOldUrlOption = (): string => {
		if (!isNil(unitRequest?.old_response?.value_option)) {
			const value_option = unitRequest.old_response.value_option
			return typeof value_option === 'object' ? value_option?.opt : value_option
		} else {
			return ''
		}
	}
	return (
		<Layout row>
			{unitRequest && (
				<Sider className='border-r border-solid border-base  bg-accent-3'>
					<WorkspaceMeta meta={unitRequest} />
				</Sider>
			)}
			<Content className='overflow-y-auto'>
				<div className='flex flex-col w-full'>
					<WorkspaceTaskTitle
						unitRequest={unitRequest}
						task={task}
						unitId={unit.id}
					/>
					{comment && (
						<InlineMessage
							variant='danger'
							className='w-full py-3 rounded-none border-b border-base border-solid'
							icon={<AlertIcon />}
						>
							<span dangerouslySetInnerHTML={{ __html: comment }} />
						</InlineMessage>
					)}
					<div className='flex flex-col space-y-2 w-full px-5 py-5 bg-accent-3'>
						<div className='w-full space-y-1'>
							<h3 className='font-semibold'>Old response: </h3>
							{getOldUrl() ? (
								<>
									<div>
										<span className='text-muted mr-2'>URL:</span>
										<a
											href={getOldUrl()}
											target='_blank'
											rel='noopener noreferrer'
										>
											{getOldUrl()}
										</a>
									</div>
									<div>
										<span className='text-muted mr-2'>URL option: </span>
										<span>{getOldUrlOption()}</span>
									</div>
								</>
							) : (
								<span className='text-muted'>No old response</span>
							)}
						</div>
						<div className='flex items-center space-x-2 w-full pt-5'>
							<WorkspaceGoogleButton
								onClick={() =>
									openLinkInGoole(
										unitRequest.competitor_url,
										unitRequest.product_title
									)
								}
							/>
							<WorkspaceBingButton
								onClick={() =>
									openLinkInBing(
										unitRequest.competitor_url,
										unitRequest.product_title
									)
								}
							/>
						</div>
					</div>
					{answerForm === 'wrong' && (
						<div className='p-5 border-t border-base border-solid bg-danger-10 dark:bg-danger-200'>
							<h5 className='mb-2'>What is correct url?</h5>
							<FormWrong
								onNotFoundClick={handleAnswerNotFound}
								onSubmit={handleAnswerFound}
								resolver={validateFormWrong}
							/>
						</div>
					)}
					<div className='p-5 border-t border-base border-solid'>
						<Row>
							<Col sm={12}>
								<Row className='mt-5'>
									<Col>
										<WorkspaceActionButton
											variant='success'
											onClick={handleCorrectClick}
										>
											<CheckIcon height={70} width={70} />
											Correct
										</WorkspaceActionButton>
									</Col>
									<Col>
										<WorkspaceActionButton
											variant='danger'
											active={answerForm === 'wrong'}
											onClick={() => {
												mixpanel.track('Wrong unit button click', {
													taskapp: 'QA',
												})
												setAnswerForm('wrong')
											}}
										>
											<CrossIcon height={70} width={70} />
											Wrong
										</WorkspaceActionButton>
									</Col>
									<Col>
										<WorkspaceActionButton
											variant='epic'
											onClick={() => {
												mixpanel.track('Skip unit button click', {
													taskapp: 'QA',
												})
												skip()
											}}
										>
											<ArrowSquaredDownRightIcon height={70} width={70} />
											Skip
										</WorkspaceActionButton>
									</Col>
								</Row>
							</Col>
							<Col sm={12} className='mt-2'>
								<p>
									Press{' '}
									<code className='bg-accent-4 rounded p-1 text-2xs'>
										Alt+W
									</code>{' '}
									for open both
								</p>
							</Col>
						</Row>
					</div>
				</div>
			</Content>
			{isQueueOpen && (
				<Sider className='border-l border-solid border-base  bg-accent-3'>
					<WorkspaceQueue queue={queue} />
				</Sider>
			)}
		</Layout>
	)
}
