import { LoginPayload } from '@/generated'
import { client } from '@/network/client'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useLoginMutation() {
	const queryClient = useQueryClient()
	return useMutation<any, any, LoginPayload>({
		mutationFn: (payload: LoginPayload) =>
			client.auth.login({ _c: new Date().getTime() }, payload),
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: ['session', 'user'],
			})
		},
	})
}
