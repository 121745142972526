import { prop } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Button, Textfield } from '@cmpkit/base'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import QueueIcon from '@cmpkit/icon/lib/glyph/queue'
import Tooltip from '@cmpkit/tooltip'

import { unsafeCopyToClipboard } from '@/utils/helpers'

import { openLinkInBing } from './templates/helpers'
import WorkspaceBingButton from './WorkspaceBingButton'
import WorkspaceGoogleButton from './WorkspaceGoogleButton'

const copyEvent = 'Copy to clipboard'
const destination = 'meta'
export default function WorkspaceMetaExtended({ meta, competitor }: any) {
	const title = getSarchTitle(meta)
	const urlInfo = getUrlInfo(meta)
	const copy = (text: string) => text && unsafeCopyToClipboard(text)
	return (
		<div>
			<div className='bg-accent-3 p-5 space-y-3'>
				<Textfield
					readOnly
					value={getLink(meta)}
					iconAfter={[
						<Tooltip key='1' placement='top' content='Copy to clipboard'>
							<Button
								dry
								variant='primary'
								ghost
								onClick={() => {
									mixpanel.track(copyEvent, {
										source: 'link',
										destination,
									})
									return unsafeCopyToClipboard(getLink(meta))
								}}
								iconBefore={<QueueIcon />}
							/>
						</Tooltip>,
						<Tooltip key='2' placement='top' content='Open in separate tab'>
							<Button
								dry
								className='ml-2'
								onClick={() => {
									mixpanel.track('Open link in separate tab')
									handleOpenSingleInTab(getLink(meta))
								}}
								variant='primary'
								ghost
								iconBefore={<LinkIcon />}
							/>
						</Tooltip>,
					]}
				/>
				<div className='flex items-center space-x-2 w-full'>
					<WorkspaceGoogleButton
						disabled={!competitor}
						onClick={() => {
							mixpanel.track('Google button clicked')
							openLinkInGoole(urlInfo.hostname, title)
						}}
					/>
					<WorkspaceBingButton
						disabled={!competitor}
						onClick={() => {
							mixpanel.track('Bing button clicked')
							openLinkInBing(urlInfo.hostname, title)
						}}
					/>
				</div>
			</div>
			<div className=''>
				<div className='flex flex-col border-t border-solid border-base'>
					<DescriptionItem
						label={'Title'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'product_title',
								destination,
							})
							copy(getTitle(meta))
						}}
					>
						{getTitle(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'SKU'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'product_sku',
								destination,
							})
							copy(getSku(meta))
						}}
					>
						{getSku(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'Vendor'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'product_vendor',
								destination,
							})
							copy(getVendor(meta))
						}}
					>
						{getVendor(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'MPN'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'product_mpn',
								destination,
							})
							copy(getMpn(meta))
						}}
					>
						{getMpn(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'UPC'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'product_upc',
								destination,
							})
							copy(getUpc(meta))
						}}
					>
						{getUpc(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'Price'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'price',
								destination,
							})
							copy(getPrice(meta))
						}}
					>
						{getPrice(meta)}
					</DescriptionItem>
					<DescriptionItem
						label={'Option'}
						onClick={() => {
							mixpanel.track(copyEvent, {
								source: 'option',
								destination,
							})
							copy(getUrlOption(meta))
						}}
					>
						{getUrlOption(meta)}
					</DescriptionItem>
				</div>
			</div>
		</div>
	)
}
function DescriptionItem({ label, children, onClick }: any) {
	return (
		<div
			className='flex space-x-2 cursor-pointer hover:bg-accent-3 py-2 px-5 relative group'
			onClick={onClick}
		>
			<span className='text-muted font-bold italic'>{label}:</span>
			<div className='font-bold cursor-text'>{children || '-'}</div>
			{!!children && (
				<div className='absolute right-5 top-2 text-muted hidden group-hover:block italic font-semibold select-none'>
					Click to copy
				</div>
			)}
		</div>
	)
}

const openLinkInGoole = (domain: string, title: string): void => {
	window.open(
		`https://www.google.com/search?q=site:${domain} ${sanitizeTitle(
			title || ''
		)}`,
		'_blank'
	)
}
const handleOpenSingleInTab = (url: string): void => {
	window.open(url, '_blank')
}
function sanitizeTitle(title = '') {
	/* eslint-disable */
	//.replace(/[^\w\s]/gi, '')
	return encodeURIComponent(
		title.replace('&', ' ').replace(/[\&\#\"\'\^\$\%\*\@\(\)\!]+/gi, ' ')
	)
	/* eslint-enable */
}
const getLink = prop('product_link')
const getUrlInfo = prop('urlInfo')
const getTitle = prop('title')
const getSarchTitle = prop('product_title')
const getSku = prop('sku')
const getVendor = prop('vendor')
const getMpn = prop('mpn')
const getUpc = prop('upc')
const getPrice = prop('price')
const getUrlOption = prop('urlOption')
