import { prop, trim } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import Mousetrap from 'mousetrap'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
	Button,
	Col,
	Content,
	FormError,
	FormGroup,
	FormLabel,
	InlineMessage,
	Layout,
	Row,
	Sider,
	Textfield,
} from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import ArrowSquaredDownRightIcon from '@cmpkit/icon/lib/glyph/arrow-squared-down-right'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'

import { WorkspaceActionButton } from '@/components/WorkspaceActionButton'
import Confirm from '@/lib/confirm'
import { blink } from '@/utils/helpers'
import { yupResolver } from '@hookform/resolvers/yup'

import { WorkspaceTemplateProps } from '../../types'
import WorkspaceBingButton from '../../WorkspaceBingButton'
import WorkspaceGoogleButton from '../../WorkspaceGoogleButton'
import WorkspaceMeta from '../../WorkspaceMeta'
import WorkspaceQueue from '../../WorkspaceQueue'
import WorkspaceTaskTitle from '../../WorkspaceTaskTitle'
import {
	getCompetitorUrl,
	getCompetitorUrlOption,
	getLocation,
	getProductTitle,
	isOptionEnabled,
	openLinkInBing,
	openLinkInGoole,
} from '../helpers'

const taskapp = 'Product Matching'
/**
 * Create validation schema for react-hook-form and yup
 *
 * @param params - object with params for validation
 * @returns - validation schema
 */
function getValidationSchema({
	competitorUrl,
	urlPattern,
}: {
	competitorUrl: string
	urlPattern: any
}) {
	const competitorHostName = getLocation(competitorUrl)?.hostname as string
	return Yup.object().shape({
		foundedUrl: Yup.string()
			.required('URL is required')
			.url('Must be a valid URL')
			.matches(new RegExp(urlPattern), `It is not valid by specific pattern`)
			.matches(
				new RegExp(competitorHostName),
				`It is not valid url/link (Must started at: ${competitorHostName})`
			),
		urlOption: Yup.string().nullable(),
	})
}

/**
 * Template for Product Matching task type
 * @param props - props for component
 * @returns
 */
export default function DefaultTemplate({
	task,
	queue,
	unit,
	unitRequest,
	skip,
	sendAnswer,
	isQueueOpen,
}: WorkspaceTemplateProps) {
	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(
			getValidationSchema({
				competitorUrl: getCompetitorUrl(unitRequest),
				urlPattern: task.url_pattern,
			})
		),
	})

	/**
	 * Reset form on unit change and blink title of task
	 */
	useEffect(() => {
		reset()
		blink('workspace_task_container')
	}, [unit.id])

	const comment = prop('comments', task)
	const productTitle = getProductTitle(unitRequest)
	const competitorUrl = getCompetitorUrl(unitRequest)

	/**
	 * Handler for open google link button
	 */
	const handleOpenGoogle = useCallback(() => {
		mixpanel.track('Hot key pressed', {
			taskapp,
			hotkey: 'Alt+Z',
		})
		openLinkInGoole(competitorUrl, productTitle)
	}, [unit.id])

	/**
	 * Bind hotkey
	 */
	useEffect(() => {
		Mousetrap.bind(['alt+z'], handleOpenGoogle)
		return () => {
			Mousetrap.unbind(['alt+z'])
		}
	}, [handleOpenGoogle])

	/**
	 * Handler for submit form as founded match
	 * @param param - object with foundedUrl and urlOption
	 */
	const handleAnswerFound = ({ foundedUrl, urlOption }: any): void => {
		mixpanel.track('Send unit answer as found', {
			taskapp,
		})
		const answer: any = {
			found: true,
			value: trim(foundedUrl),
		}
		if (urlOption) {
			answer['value_option'] = trim(urlOption)
		}
		sendAnswer(answer)
	}

	/**
	 * Handler for submit form as not found match
	 */
	const handleAnswerNotFound = async () => {
		mixpanel.track('Not found unit click', {
			taskapp,
		})
		const answer = await Confirm.confirm({
			title: 'Are you sure?',
		})
		if (answer) {
			mixpanel.track('Send unit answer as not found', {
				taskapp,
			})
			sendAnswer({
				found: false,
				value: '',
			})
		}
	}

	return (
		<Layout row className='overflow-hidden '>
			{unitRequest && (
				<Sider className='border-r border-solid border-base  bg-accent-3'>
					<WorkspaceMeta meta={unitRequest} />
				</Sider>
			)}
			<Content className='overflow-y-auto bg-accent-1'>
				<div className='flex flex-col w-full '>
					<WorkspaceTaskTitle
						unitRequest={unitRequest}
						task={task}
						unitId={unit.id}
					/>
					<form onSubmit={handleSubmit(handleAnswerFound)}>
						<div className='p-5 border-b border-base border-solid bg-accent-2'>
							{comment && (
								<div className='pb-5'>
									<InlineMessage
										variant='danger'
										className='w-full py-3'
										icon={<AlertIcon />}
									>
										<span dangerouslySetInnerHTML={{ __html: comment }} />
									</InlineMessage>
								</div>
							)}
							<FormGroup>
								<FormLabel>Product URL</FormLabel>
								<Textfield
									className='ws-input'
									autoComplete='off'
									autoCorrect='off'
									invalid={!!errors.foundedUrl}
									{...register('foundedUrl', { required: true })}
									placeholder={'Paste competiotor product url here...'}
									iconAfter={
										watch('foundedUrl') && (
											<Button
												quite
												className='py-1.5'
												iconBefore={<CrossIcon />}
												onClick={() =>
													reset({
														foundedUrl: '',
													})
												}
											/>
										)
									}
								/>
								{errors.foundedUrl && (
									<FormError>{errors?.foundedUrl?.message as string}</FormError>
								)}
							</FormGroup>
							{!!isOptionEnabled(unitRequest) && (
								<FormGroup>
									<FormLabel>
										{getCompetitorUrlOption(unitRequest) || 'Option'}
									</FormLabel>
									<Textfield
										autoComplete='off'
										autoCorrect='off'
										{...register('urlOption')}
										placeholder={'Type url option here...'}
									/>
									{errors.urlOption && (
										<FormError>
											{errors?.urlOption?.message as string}
										</FormError>
									)}
								</FormGroup>
							)}
							<div className='flex items-center space-x-2 w-full pt-5'>
								<WorkspaceGoogleButton
									onClick={() => openLinkInGoole(competitorUrl, productTitle)}
								/>
								<WorkspaceBingButton
									onClick={() => openLinkInBing(competitorUrl, productTitle)}
								/>
							</div>
						</div>
						<div className='p-5'>
							<Row>
								<Col>
									<WorkspaceActionButton
										variant='success'
										onClick={handleSubmit(handleAnswerFound)}
									>
										<CheckIcon height={70} width={70} />
										Found
									</WorkspaceActionButton>
								</Col>
								<Col>
									<WorkspaceActionButton
										variant='danger'
										onClick={handleAnswerNotFound}
									>
										<CrossIcon height={70} width={70} />
										Not found
									</WorkspaceActionButton>
								</Col>

								<Col>
									<WorkspaceActionButton
										variant='epic'
										onClick={() => {
											mixpanel.track('Skip unit button click', {
												taskapp,
											})
											skip()
										}}
									>
										<ArrowSquaredDownRightIcon height={70} width={70} />
										Skip
									</WorkspaceActionButton>
								</Col>
							</Row>
							<div className='mt-2'>
								<p className='py-2'>
									Press{' '}
									<code className='bg-accent-4 rounded p-1 text-2xs'>
										Alt+Z
									</code>{' '}
									for search in Google
								</p>
							</div>
						</div>
					</form>
				</div>
			</Content>
			{isQueueOpen && (
				<Sider className='border-l border-solid border-base  bg-accent-3'>
					<WorkspaceQueue queue={queue} />
				</Sider>
			)}
		</Layout>
	)
}
