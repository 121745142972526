import { prop, trim } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import Mousetrap from 'mousetrap'
import React, { useCallback, useEffect, useState } from 'react'

import {
	Button,
	Col,
	Content,
	InlineMessage,
	Layout,
	Row,
	Sider,
} from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'
import ArrowSquaredDownRightIcon from '@cmpkit/icon/lib/glyph/arrow-squared-down-right'
import CheckIcon from '@cmpkit/icon/lib/glyph/check'
import CrossIcon from '@cmpkit/icon/lib/glyph/cross'
import LinkIcon from '@cmpkit/icon/lib/glyph/link'
import SlashIcon from '@cmpkit/icon/lib/glyph/slash'
import Tooltip from '@cmpkit/tooltip'

import { WorkspaceActionButton } from '@/components/WorkspaceActionButton'
import { blink } from '@/utils/helpers'

import { WorkspaceTemplateProps } from '../../types'
import WorkspaceMetaExtended from '../../WorkspaceMetaExtended'
import WorkspaceQueue from '../../WorkspaceQueue'
import WorkspaceTaskTitle from '../../WorkspaceTaskTitle'
import {
	getCompetitiorMeta,
	getCompetitorProductUrl,
	getCompetitorProductUrlOption,
	getCustomerMeta,
	isValidUrl,
	openBoth,
} from '../helpers'
import FormWrong from './FormWrong'

const taskapp = 'HPD'
export default function HPDTemplate({
	task,
	queue,
	unit,
	unitRequest,
	skip,
	notAvailable,
	sendAnswer,
	isQueueOpen,
}: WorkspaceTemplateProps) {
	/**
	 * State for answer form (same/different)
	 */
	const [answerForm, setAnswerForm] = useState<null | string>(null)

	/**
	 * Open both links in new tabs
	 */
	const handleOpenBoth = useCallback(() => openBoth(unitRequest), [unit.id])

	/**
	 * Bind keyboard shortcut for opening both links
	 */
	useEffect(() => {
		Mousetrap.bind(['alt+w'], handleOpenBoth)
		blink('workspace_task_container')
		return () => {
			Mousetrap.unbind(['alt+w'])
			setAnswerForm(null)
		}
	}, [unit.id])

	const comment = prop('comments', task)

	/**
	 * Handle answer form submit as same
	 */
	const handleSameClick = () => {
		mixpanel.track('Send unit answer as the same', {
			taskapp,
		})
		sendAnswer({
			answer: 'same',
			new_matching_found: false,
			url: null,
			url_option: null,
			not_found_flag: false,
		})
	}

	/**
	 * Handle answer form submit as different
	 * @param params - object with params for validation
	 */
	const handleAnswerFound = ({ correctUrl, urlOption }: any): void => {
		mixpanel.track('Send unit answer as different and found', {
			taskapp,
		})
		sendAnswer({
			answer: 'different',
			new_matching_found: true,
			url: trim(correctUrl),
			url_option: urlOption,
			not_found_flag: false,
		})
	}

	/**
	 * Handle answer form submit as not found
	 */
	const handleAnswerNotFound = (): void => {
		mixpanel.track('Send unit answer as different and not found', {
			taskapp,
		})
		sendAnswer({
			answer: 'different',
			new_matching_found: false,
			url: null,
			url_option: null,
			not_found_flag: true,
		})
	}

	/**
	 * Handle answer form submit as wrong url (with validation)
	 * @param param - object with params for validation
	 * @returns - object with errors
	 */
	const validateFormWrong = (values: any) => {
		const { correctUrl, urlOption } = values
		const errors: any = {}
		const oldUrl = getCompetitorProductUrl(unitRequest)
		const oldUrlOption = getCompetitorProductUrlOption(unitRequest)
		if (!correctUrl) {
			errors['correctUrl'] = {
				message: 'URL is required',
			}
		} else if (!isValidUrl(correctUrl, getCompetitorProductUrl(unitRequest))) {
			errors['correctUrl'] = {
				message: 'URL should be on the same domain as the competotor URL',
			}
		} else {
			if (correctUrl === oldUrl && oldUrlOption === urlOption) {
				errors['correctUrl'] = {
					message: 'Previous URL was selected',
				}
				errors['urlOption'] = {
					message: 'Previous option was selected',
				}
			}
		}
		return {
			values,
			errors,
		}
	}

	return (
		<Layout row>
			<Content className='overflow-y-auto bg-accent-1'>
				<div className='flex flex-col w-full'>
					<WorkspaceTaskTitle
						unitRequest={unitRequest}
						task={task}
						unitId={unit.id}
					/>
					{comment && (
						<div className='p-5 border-b border-base border-solid'>
							<InlineMessage
								variant='danger'
								className='w-full py-3'
								icon={<AlertIcon />}
							>
								<span dangerouslySetInnerHTML={{ __html: comment }} />
							</InlineMessage>
						</div>
					)}
					<Row
						noGutters
						className='border-b border-base border-solid bg-accent-2 relative'
					>
						<Col sm='6' className='border-r border-base border-solid'>
							<WorkspaceMetaExtended meta={getCustomerMeta(unitRequest)} />
						</Col>
						<div className='absolute top-5 left-1/2 z-50 -ml-4'>
							<Tooltip placement='top' content='Open both in separate tabs'>
								<Button
									block
									onClick={handleOpenBoth}
									iconBefore={<LinkIcon />}
								/>
							</Tooltip>
						</div>
						<Col sm='6'>
							<WorkspaceMetaExtended
								competitor
								meta={getCompetitiorMeta(unitRequest)}
							/>
						</Col>
					</Row>
					{answerForm === 'wrong' && (
						<div className='p-5 border-b border-base border-solid bg-danger-10 dark:bg-danger-200'>
							<h3 className='font-bold'>What is correct url?</h3>
							<FormWrong
								onNotFoundClick={handleAnswerNotFound}
								onSubmit={handleAnswerFound}
								resolver={validateFormWrong}
							/>
						</div>
					)}
					<div className='p-5'>
						<Row>
							<Col>
								<WorkspaceActionButton
									variant='success'
									active={answerForm === 'same'}
									onClick={handleSameClick}
								>
									<CheckIcon height={70} width={70} />
									Same
								</WorkspaceActionButton>
							</Col>
							<Col>
								<WorkspaceActionButton
									variant='danger'
									active={answerForm === 'wrong'}
									onClick={() => {
										mixpanel.track('Wrong unit button click', {
											taskapp,
										})
										setAnswerForm('wrong')
									}}
								>
									<CrossIcon height={70} width={70} />
									Wrong
								</WorkspaceActionButton>
							</Col>
							<Col>
								<WorkspaceActionButton variant='warn' onClick={notAvailable}>
									<SlashIcon height={70} width={70} />
									Not available
								</WorkspaceActionButton>
							</Col>
							<Col>
								<WorkspaceActionButton
									variant='epic'
									onClick={() => {
										mixpanel.track('Skip unit button click', {
											taskapp,
										})
										skip()
									}}
								>
									<ArrowSquaredDownRightIcon height={70} width={70} />
									Skip
								</WorkspaceActionButton>
							</Col>
						</Row>
						<div className='mt-2'>
							<p>
								Press{' '}
								<code className='bg-accent-4 rounded p-1 text-2xs'>Alt+W</code>{' '}
								for open both
							</p>
						</div>
					</div>
				</div>
			</Content>
			{isQueueOpen && (
				<Sider className='border-l border-solid border-base  bg-accent-3'>
					<WorkspaceQueue queue={queue} />
				</Sider>
			)}
		</Layout>
	)
}
