import React from 'react'

import { QuizProvider } from '@/features/instructions/context'
import { WorkspaceProvider } from '@/features/workspace/context'

import WorkspacePage from './WorkspacePage'

export default function WorkspaceLayout() {
	return (
		<WorkspaceProvider>
			<QuizProvider>
				<WorkspacePage />
			</QuizProvider>
		</WorkspaceProvider>
	)
}
