import { first, lt, pipe, prop, size } from 'lodash/fp'
import React from 'react'
import { Navigate } from 'react-router'

import { Result } from '@cmpkit/base'
import AwardIcon from '@cmpkit/icon/lib/glyph/award'

import { useTasksQuery } from '@/features/tasks/queries'

export default function TasksPage() {
	const tasksQuery = useTasksQuery()
	if (tasksQuery.isFetching) {
		return <div>Fetching</div>
	}
	if (hasItems(tasksQuery.data) && hasAvailableUnits(tasksQuery.data)) {
		return <Navigate to={'/app/workspace'} />
	}
	return (
		<Result
			className={'m-auto'}
			icon={<AwardIcon width={80} height={80} />}
			subtitle={'Task with units is not available for you now'}
		/>
	)
}

const hasItems = pipe([prop('items'), size, lt(0)])
const hasAvailableUnits = pipe([prop('items'), first, prop('available'), lt(0)])
