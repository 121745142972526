import { entries, pickAll } from 'lodash/fp'
import React from 'react'

import { unsafeCopyToClipboard } from '@/utils/helpers'

export default function WorkspaceMeta({ meta }: any) {
	const data = entries(
		pickAll(
			[
				'product_title',
				'product_sku',
				'product_vendor',
				'product_upc',
				'product_mpn',
				'category_name',
				'campaign_name',
			],
			meta
		)
	)
	return (
		<div className='flex flex-col overflow-hidden h-full'>
			<div className='px-5 py-2 border-base border-b border-solid bg-accent-1'>
				<h3 className='font-bold text-muted text-lg'>Metadata</h3>
			</div>
			<div className='flex flex-col divide-y divide-solid divide-base overflow-y-auto'>
				{data.length > 0
					? data.map(([key, value]) => {
							return (
								<div
									key={key}
									className='cursor-pointer px-5 py-2 hover:text-primary hover:bg-accent-4'
									onClick={
										value ? () => unsafeCopyToClipboard(value) : () => null
									}
								>
									<div className='capitalize italic font-bold text-muted'>
										{key}:
									</div>
									<div className='flex text-lg font-bold'>{value || '-'}</div>
								</div>
							)
					  })
					: 'Empty'}
			</div>
		</div>
	)
}
