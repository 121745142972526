import { findIndex, prop, size } from 'lodash/fp'
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useParams } from 'react-router'

import { useQueryClient } from '@tanstack/react-query'

import { Loader } from '@cmpkit/base'

import { TaskModel, TasksItemModel, TaskUnitItemModel } from '@/generated'
import Confirm from '@/lib/confirm'
import logger from '@/lib/logger'
import { client } from '@/network/client'
import { generateSalt } from '@/utils/helpers'

import { useTasksQuery } from '../tasks/queries'
import { useSendAnswerMutation } from './mutations'
import { useWorkspaceQuery } from './queries'
import { useAnswersLogStore } from './store'

export interface WorkspaceContextValue {
	tasks?: TasksItemModel[]
	task?: TaskModel
	unit?: TaskUnitItemModel
	queue?: TaskUnitItemModel[]
	next(): any
	goToNextTask(): Promise<any>
	skipUnit(): any

	sendAnswer: any
}
export interface WorkspaceProviderProps {
	children: React.ReactNode
}
export const WorkspaceContext =
	React.createContext<WorkspaceContextValue | null>(null)

export function WorkspaceProvider({
	children,
}: WorkspaceProviderProps): JSX.Element {
	/**
	 * Common hooks
	 */
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { addToLog } = useAnswersLogStore()
	const { tid } = useParams()
	const [unitIndex, setUnitIndex] = useState(0)
	const taskId = Number(tid)

	useEffect(() => {
		unitIndex !== 0 && setUnitIndex(0)

		return () => {
			queryClient.removeQueries({
				queryKey: ['task'],
			})
		}
	}, [tid])

	/**
	 * Queries
	 */
	const tasksQuery = useTasksQuery<TasksItemModel[]>({
		select: prop('items') as any,
	})
	const workspaceQuery = useWorkspaceQuery(
		String(taskId),
		{
			offset: 0,
			count: 10,
		},
		{ retry: false }
	)

	/**
	 * Mutations
	 */
	const sendAnswerMutation = useSendAnswerMutation({
		onMutate(variables: any) {
			addToLog(`${variables.task_id}::${variables.id}`, 'pending')
			toast.loading('Loading...', {
				id: 'work',
				duration: 10000,
			})
		},
		onError(error, variables: any) {
			logger.error('answer error', error)
			addToLog(`${variables.task_id}::${variables.id}`, 'error')
			toast.error('Your answer has not been sent', {
				id: 'work',
				duration: 3000,
			})
			next()
		},
		onSuccess: (data, variables: any) => {
			logger.info('WORK', 'answer success')
			addToLog(`${variables.task_id}::${variables.id}`, 'success')
			toast.success(
				'Your answer has been sent, you will be redirected to the next unit/task',
				{
					id: 'work',
					duration: 3000,
				}
			)
			next()
		},
	})

	/**
	 * Calculated values
	 */
	const queue: TaskUnitItemModel[] = prop('items', workspaceQuery.data) || []
	const task = workspaceQuery?.data
	const tasks: TasksItemModel[] = tasksQuery?.data || []
	const unit = queue?.[unitIndex]
	const taskIndex = findIndex(['id', taskId], tasks)
	const nextTask = tasks[taskIndex + 1]

	/**
	 * Go to next task or workspace index page
	 */
	const goToNextTask = useCallback(async () => {
		if (nextTask) {
			await generateSalt(nextTask.id as any)
			logger.info('WORK', `go to next task ${nextTask?.id} of ${size(tasks)}`)
			navigate('/app/workspace/' + nextTask?.id)
		} else {
			logger.info('WORK', `go workspace index`)
			navigate('/app/workspace')
		}
	}, [nextTask, navigate])
	/**
	 * Handle on next unit
	 */
	const next = useCallback(async () => {
		const nextUnitIndex = unitIndex + 1
		if (nextUnitIndex < queue.length) {
			logger.info('WORK', `go to next unit ${nextUnitIndex} of ${queue.length}`)
			return setUnitIndex(nextUnitIndex)
		} else {
			try {
				logger.info('WORK', `try to get more units of current task ${taskId}`)
				await generateSalt(taskId)
				const newTask = await workspaceQuery.refetch()
				if (newTask.data?.items?.length === 0) {
					await tasksQuery.refetch()
					goToNextTask()
				} else {
					logger.info('WORK', 'received new units of current task')
					setUnitIndex(0)
				}
			} catch (error) {
				await tasksQuery.refetch()
				goToNextTask()
			}
		}
	}, [setUnitIndex, unitIndex, queue])

	/**
	 * Handle on send answer
	 */
	const sendAnswer = useCallback(
		(body: any) => {
			sendAnswerMutation.mutateAsync(body)
		},
		[sendAnswerMutation]
	)

	/**
	 * Handle on skip unit
	 */
	const skipUnit = async () => {
		const answer = await Confirm.confirm({
			title: 'Are you sure?',
			message: 'Do you really want skip this task unit?',
		})
		if (answer && unit) {
			client.work.skipUnit({ unit_id: unit.id }).then((r) => {
				if (r.success) {
					logger.info('WORK', `unit ${unit.id} skiped`)
					addToLog(`${task?.id}::${unit.id}`, 'success')
					toast(
						`You have skipped unit #${unit.id}, you will be redirected to the next unit/task`,
						{
							id: 'work',
							duration: 3000,
						}
					)
					next()
				}
			})
		}
	}

	/**
	 * Context value
	 */
	const value = React.useMemo(
		() => ({
			queue,
			task,
			tasks,
			unit,
			next,
			sendAnswer,
			skipUnit,
			goToNextTask,
		}),
		[queue, task, tasks, unit, next, goToNextTask, sendAnswer, skipUnit]
	)

	if (workspaceQuery.isError) {
		return <Navigate to='/app/workspace' />
	}
	if (workspaceQuery.isLoading) {
		return (
			<div className='h-full w-full flex flex-col items-center justify-center '>
				<Loader />
				<p className='text-muted mt-2 text-xs'>
					Loading task and task units...
				</p>
			</div>
		)
	}
	return (
		<WorkspaceContext.Provider value={value as any}>
			{children}
		</WorkspaceContext.Provider>
	)
}
export function useWorkspace() {
	const context = React.useContext(WorkspaceContext)
	if (!context) {
		throw new Error(`useWorkspace must be used within an WorkspaceProvider`)
	}
	return context
}
