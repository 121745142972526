import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import AlertCircleIcon from '@cmpkit/icon/lib/glyph/alert-circle'
import { Modal, ModalBody, ModalTransition } from '@cmpkit/modal'

export default function WorkspaceNotifyWhenCompetitorChange({
	competiotorName,
}: {
	competiotorName: string
}) {
	const [isOpen, setIsOpen] = useState(false)
	const [lastCompetiotorName, setLastCompetiotorName] = useLocalStorage<
		string | undefined
	>('last_competitor_name', undefined)
	useEffect(() => {
		if (lastCompetiotorName !== competiotorName) {
			setIsOpen(true)
			setTimeout(() => {
				setIsOpen(false)
			}, 1000)
			setLastCompetiotorName(competiotorName)
		}
	}, [competiotorName])
	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					zIndex={1850}
					onClose={() => setIsOpen(false)}
					size='small'
					width={400}
				>
					<ModalBody>
						<div className='flex justify-center mb-5'>
							<AlertCircleIcon width={80} height={80} className='fill-warn' />
						</div>
						<p className='text-xl font-bold text-center'>
							Task was changed, please pay attention to the Competitor name
						</p>
						<p className='text-muted text-center mt-5'>{competiotorName}</p>
					</ModalBody>
				</Modal>
			)}
		</ModalTransition>
	)
}
