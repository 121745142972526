import HPDTemplate from './HPD/HPDTemplate'
import MLQATemplate from './MLQA/MLQATemplate'
import ProductMatching from './ProductMatching/ProductMatchingTemplate'
import QATemplate from './QA/QATemplate'

export function getTemplateByTaskappId(taskappId?: number) {
	switch (taskappId) {
		case 1:
			return ProductMatching
		case 24:
			return HPDTemplate
		case 25:
			return QATemplate
		case 27:
			return MLQATemplate
		default:
			return ProductMatching
	}
}
