import { prop } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Button } from '@cmpkit/base'

import { useAnswersLogStore } from '@/features/workspace/store'
import { TaskUnitItemModel } from '@/generated'
import { parseJson } from '@/utils/helpers'

export default function WorkspaceQueue({
	queue,
}: {
	queue: TaskUnitItemModel[]
}) {
	const answersLog = useAnswersLogStore(prop('list'))
	const filteredQueue = queue.filter(
		(unit) => !answersLog[`${unit.task_id}::${unit.id}`]
	)
	const handleOpenAllClick = () => {
		mixpanel.track('Open all units new windows', {
			items: filteredQueue.length,
		})
		filteredQueue
			.map((unit) => parseJson(unit.request)?.product_my_url)
			.filter(Boolean)
			.forEach((url: string) => {
				window.open(url, url)
			})
	}
	return (
		<div className='flex flex-col overflow-hidden h-full'>
			<div className='px-5 py-2 border-base border-b border-solid flex space-x-2 justify-between items-center bg-accent-1'>
				<h3 className='font-bold text-muted text-lg'>Queue</h3>
				<Button size='small' onClick={handleOpenAllClick}>
					Open all
				</Button>
			</div>
			<div className='flex flex-col divide-y divide-solid divide-base overflow-y-auto'>
				{filteredQueue.map((unit) => {
					const data = parseJson(unit.request)
					return (
						<a
							href={data.product_my_url}
							onClick={() => {
								mixpanel.track('Open unit url in new window')
							}}
							key={unit.id}
							target='_blanc'
							className={
								'px-5 py-2 hover:bg-accent-4 cursor-pointer no-underline'
							}
						>
							<div className='font-semibold'>{data.product_title}</div>
							<div className='italic text-muted'>{data.category_name}</div>
						</a>
					)
				})}
			</div>
		</div>
	)
}
