import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom'

/* Components */
import DelayedMount from '@/components/DelayedMount'
import ErrorBoundary from '@/components/ErrorBoundary'
import FullscreenLoading from '@/components/FullscreenLoading'
/* Scenes */
import { useAuth } from '@/features/auth/authentication'
import Sentry from '@/lib/sentry'
import Authentication from '@/scenes/Authentication'
import { ProfilePage } from '@/scenes/Profile'
import ProfileTiers from '@/scenes/Profile/ProfileTiers'
import { TasksPage } from '@/scenes/Tasks'
import WelcomeScene from '@/scenes/WelcomeScene'
import { WorkspaceIndexPage } from '@/scenes/Workspace'
import WorkspaceLayout from '@/scenes/Workspace/WorkspaceLayout'

import ApplicationLayout from '../Layouts/ApplicationLayout'

export default function RootRoter() {
	const { user } = useAuth()
	useEffect(() => {
		if (user?.id) {
			Sentry.setUser({
				role: user.group,
				email: user.email,
				username: user.login,
				id: String(user.id),
			})
		}
	}, [])
	return (
		<BrowserRouter>
			<AnimatePresence mode='wait'>
				<React.Suspense
					fallback={
						<DelayedMount delay={2000}>
							<FullscreenLoading />
						</DelayedMount>
					}
				>
					<AnimatePresence mode='wait'>
						<ErrorBoundary>
							{user && user.id ? (
								<Routes>
									<Route path='/app' element={<ApplicationLayout />}>
										<Route index element={<WelcomeScene />} />
										<Route path='profile' element={<ProfilePage />} />
										<Route path='tiers' element={<ProfileTiers />} />
										<Route
											path='/app/workspace/:tid'
											element={<WorkspaceLayout />}
										/>
										<Route
											path='/app/workspace'
											element={<WorkspaceIndexPage />}
										/>
										<Route path='/app/tasks' element={<TasksPage />} />
									</Route>
									<Route path='*' element={<Navigate to='/app' />} />
								</Routes>
							) : (
								<Routes>
									{/* <Route index element={<HardNavigate to={getLoginUrl()} />} /> */}
									<Route index element={<Authentication />} />
									<Route path='*' element={<Navigate to='/' />} />
								</Routes>
							)}
						</ErrorBoundary>
					</AnimatePresence>
				</React.Suspense>
			</AnimatePresence>
		</BrowserRouter>
	)
}
export function HardNavigate({ to }: any) {
	useEffect(() => {
		window.location.href = to
	}, [to])
	return null
}

export { BrowserRouter as Router, Route, Routes, Link }
