import mixpanel from 'mixpanel-browser'
import React from 'react'
import { useNavigate } from 'react-router'

import {
	Button,
	Col,
	Content,
	Header,
	Layout,
	Panel,
	PanelBody,
	PanelHeader,
	PanelTitle,
	Row,
} from '@cmpkit/base'
import CaretLeftIcon from '@cmpkit/icon/lib/glyph/caret-left'

import { useSaveProfileMutation } from '@/features/account/mutations'
import { useAuth } from '@/features/auth/authentication'

import ProfileForm from './ProfileForm'
import ProfileStatistics from './ProfileStatistics'

export default function ProfilePage() {
	const { user } = useAuth()
	const navigate = useNavigate()
	const updateProfileMutation = useSaveProfileMutation()
	const handleProfileSubmit = async ({ password, password2, email }: any) => {
		mixpanel.track('Update profile information')
		if (password) {
			if (password === password2) {
				await updateProfileMutation.mutateAsync({
					password,
				})
			}
		} else {
			await updateProfileMutation.mutateAsync({
				email,
			})
		}
	}
	return (
		<Layout className='w-full'>
			<Header className='border-b border-solid border-base px-5 py-2 bg-accent-3 flex items-center space-x-2'>
				<Button
					size='mini'
					iconBefore={<CaretLeftIcon />}
					onClick={() => navigate('/app')}
				/>
				<h5 className='font-bold'>Account settings</h5>
			</Header>
			<Content className='w-full p-5'>
				<Row className='w-full'>
					<Col sm='4'>
						<Panel>
							<PanelHeader>
								<PanelTitle>General account information</PanelTitle>
							</PanelHeader>
							<PanelBody>
								<ProfileForm
									defaultValues={user}
									onSubmit={handleProfileSubmit}
								/>
							</PanelBody>
						</Panel>
					</Col>
					<Col sm='8'>
						<Panel>
							<PanelHeader>
								<PanelTitle>Account statistics</PanelTitle>
							</PanelHeader>
							<PanelBody>
								<ProfileStatistics />
							</PanelBody>
						</Panel>
					</Col>
				</Row>
			</Content>
		</Layout>
	)
}
