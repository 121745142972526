import { client } from '@/network/client'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { encodeInstructionsData } from './utils'

export function useInstructionAnswerMutation(
	options?: UseMutationOptions<
		any,
		any,
		{ user_id: number; instruction_id: number }[]
	>
) {
	return useMutation<any, any, { user_id: number; instruction_id: number }[]>({
		mutationFn: (body: { user_id: number; instruction_id: number }[]) =>
			Promise.all(
				body.map((item: any) =>
					client.instructions.sendAnswer1(
						{ _c: new Date().getTime() },
						{ body: encodeInstructionsData(item) }
					)
				)
			),
		...(options || {}),
	})
}
