import { useLocalStorage } from 'usehooks-ts'

export function useTheme(key = 'theme') {
	const [theme, setDarkTheme] = useLocalStorage(key, 'light')

	const toggleTheme = () => {
		const newTheme = theme === 'dark' ? 'light' : 'dark'
		setDarkTheme(newTheme)
		document.body.setAttribute('data-theme', newTheme)
	}
	return [theme, toggleTheme]
}
