import env from '@/env'

import Sentry from './sentry'

const commonStyles = ['color: white', 'display: block', 'text-align: center']

const logStyles = {
	success: ['background: green', ...commonStyles].join(';'),
	error: ['background: red', ...commonStyles].join(';'),
	info: ['background: blue', ...commonStyles].join(';'),
}

type LogCategory = string

type Extra = Record<string, any>

class Logger {
	/**
	 * Log information
	 *
	 * @param category A log message category that will be prepended
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	info(label: LogCategory, message: string) {
		console.log(`%c [${label}] ${message}`, logStyles.info)
	}

	/**
	 * Debug information
	 *
	 * @param category A log message category that will be prepended
	 * @param extra Arbitrary data to be logged
	 */
	debug(label: LogCategory, message: string) {
		if (env.ENVIRONMENT === 'development') {
			console.info(`[${label}] ${message}`, logStyles.info)
		}
	}

	/**
	 * Log a warning
	 *
	 * @param message A warning message
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	warn(message: string, extra?: Extra) {
		if (env.SENTRY_DSN) {
			Sentry.withScope(function (scope) {
				scope.setLevel('warning')

				for (const key in extra) {
					scope.setExtra(key, extra[key])
				}

				Sentry.captureMessage(message)
			})
		}

		console.warn(message, logStyles.info)
	}

	/**
	 * Report a runtime error
	 *
	 * @param message A description of the error
	 * @param error The error that occurred
	 * @param extra Arbitrary data to be logged that will appear in prod logs
	 */
	error(message: string, error: Error, extra?: Extra) {
		if (env.SENTRY_DSN) {
			Sentry.withScope(function (scope) {
				scope.setLevel('error')

				for (const key in extra) {
					scope.setExtra(key, extra[key])
				}

				Sentry.captureException(error)
			})
		}

		console.error(message, logStyles.error)
	}
	trace = (msg: string) => {
		if ('group' in console && 'groupEnd' in console && 'dir' in console) {
			console.group('%c TRACE', logStyles.success)
			console.log(msg)
			console.groupEnd()
		}
	}
}

export default new Logger()
