import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Header } from '@cmpkit/base'
import CompareIcon from '@cmpkit/icon/lib/glyph/compare'

import {
	getCampaignName,
	getCompetitorName,
	getCompetitorProductUrl,
	getCompetitorUrl,
	getProductUrl,
} from './templates/helpers'
import WorkspaceNotifyWhenCompetitorChange from './WorkspaceNotifyWhenCompetitorChange'
import WorkspaceTimer from './WorkspaceTimer'

type WorkspaceTaskTitleProps = {
	unitRequest: any
	task: any
	children?: any
	unitId: any
}
export default function WorkspaceTaskTitle({
	unitRequest,
	task,
	children,
	unitId,
}: WorkspaceTaskTitleProps) {
	const competiotorName = getCompetitorName(unitRequest)
	const competiotorLink =
		getCompetitorProductUrl(unitRequest) ||
		getCompetitorUrl(unitRequest) ||
		competiotorName

	return (
		<Header
			id='workspace_task_container'
			className='flex justify-between items-center border-base border-b border-solid bg-accent-1 h-11'
		>
			<WorkspaceNotifyWhenCompetitorChange competiotorName={competiotorName} />
			<div className='flex items-center space-x-4 ml-5'>
				<a
					className='text-xl font-bold no-underline'
					target='_blanc1'
					onClick={() => {
						mixpanel.track('Navigate to source url from header')
					}}
					href={getProductUrl(unitRequest) || 'https://www.google.com'}
				>
					{getCampaignName(task)}
				</a>
				<CompareIcon className='shrink-0' />
				<a
					className='text-xl font-bold no-underline'
					target='_blanc2'
					onClick={() => {
						mixpanel.track('Navigate to competitor url from header')
					}}
					href={competiotorLink}
				>
					{competiotorName}
				</a>
			</div>
			<WorkspaceTimer unitId={unitId} />
			{children}
		</Header>
	)
}
