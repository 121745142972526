import mixpanel from 'mixpanel-browser'
import React from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage, useTimeout } from 'usehooks-ts'

import { Badge, Button, Header, Layout, Result } from '@cmpkit/base'
import BurgerIcon from '@cmpkit/icon/lib/glyph/burger'
import InfoIcon from '@cmpkit/icon/lib/glyph/info'
import SearchIcon from '@cmpkit/icon/lib/glyph/search'

import { useAuth } from '@/features/auth/authentication'
import { useWorkspace } from '@/features/workspace/context'
import Confirm from '@/lib/confirm'
import { parseJson } from '@/utils/helpers'

import { getTemplateByTaskappId } from './templates'
import { useTimer } from './templates/helpers'
import WorkspaceInstructions from './WorkspaceInstructions'

export default function WorkspacePage() {
	const navigate = useNavigate()
	const { user, logout } = useAuth()
	const { queue, task, unit, next, sendAnswer, skipUnit, goToNextTask } =
		useWorkspace()
	const [isQueueOpen, setIsQueueOpen] = useLocalStorage('isQueueOpen', true)

	//  Handle on time end
	const onTimeEnd = () => {
		mixpanel.track('Unit time is over')
		Confirm.alert({
			message: 'Time is over. You will be logout from app.',
		}).then(() => {
			logout()
			window.location.reload()
		})
	}

	// Timer hook for task
	const timer = useTimer({ onTimeEnd, defaultTime: 900 }, unit?.id)

	if (!task) {
		return (
			<Result
				className={'m-auto'}
				icon={<SearchIcon width={80} height={80} />}
				title={'Task not found'}
				subtitle={'Go to the tasks side bar and select a task'}
			/>
		)
	}

	/**
	 * send answer to server
	 * @param answer
	 */
	const send = (answer: any) => {
		const body = {
			action: 'response',
			response: JSON.stringify(answer),
			id: unit?.id,
			task_id: task.id,
			execution_eta: 900 - timer.count,
			meta: user?.login,
		}
		sendAnswer(body)
	}
	/**
	 * Handle on not available unit
	 */
	const notAvailable = async () => {
		mixpanel.track('Unit is not available click')
		const answer = await Confirm.confirm({
			title: 'Are you sure?',
			message: 'Do you really want mark this unit as uavailable?',
		})
		if (answer && unit) {
			mixpanel.track('Unit is not available confirmed')
			send({
				answer: 'same',
				new_matching_found: false,
				url: null,
				url_option: null,
				not_found_flag: true,
			})
		}
	}

	// Select workspace taskapp template
	const TaskAppTemplate = getTemplateByTaskappId(task?.taskapp_id)

	// parsed unit request
	const unitRequest = unit ? parseJson(unit.request) : {}

	if (task && queue?.length === 0) {
		return (
			<Result
				className={'m-auto'}
				icon={<InfoIcon width={80} height={80} />}
				title={`Task has no units to work`}
				subtitle={`Task #${task.id} has no units to show. Go to the tasks side bar and select a task or click button blow`}
			>
				<div className='flex flex-col space-y-2'>
					<div className='space-x-2'>
						<Button onClick={() => navigate('/')} autoFocus>
							Go to home
						</Button>
						<Button onClick={goToNextTask} autoFocus variant='primary'>
							Go to the next task
						</Button>
					</div>
					<NavigateToNextTask goToNextTask={goToNextTask} />
					<div className='bg-accent-1 py-3 px-5 rounded border border-solid border-base text-left'>
						<h5 className='font-semibold text-md'>Possible reasons:</h5>
						<ul className='list-disc list-inside mt-2 text-muted'>
							<li>Task has no more units</li>
							<li>Task has no more units for your role</li>
							<li>We have technical maintenance now</li>
						</ul>
						<h5 className='font-semibold text-md'>Possible solutions:</h5>
						<ul className='list-disc list-inside mt-2 text-muted'>
							<li>Try to login again</li>
							<li>Try to clean your cookies</li>
						</ul>
						<p>
							Try again later or contact your manager if you think this is a
							bug.
						</p>
					</div>
				</div>
			</Result>
		)
	}
	// check if all data is loaded
	if (queue && unit && task) {
		return (
			<Layout>
				<Header className='border-b border-solid border-base px-5 h-10 bg-accent-3 flex items-center space-x-2 justify-between'>
					<div className='flex items-center space-x-2'>
						<h4 className='font-bold text-muted'>{task.title}</h4>
						<Badge>{task.taskapp}</Badge>
					</div>
					<div className='flex items-center space-x-2'>
						<WorkspaceInstructions />
						<Button
							quite
							dry
							className='ml-auto'
							iconBefore={<BurgerIcon />}
							onClick={() => {
								isQueueOpen
									? mixpanel.track('Close queue panel')
									: mixpanel.track('Open queue panel')
								setIsQueueOpen(!isQueueOpen)
							}}
						/>
					</div>
				</Header>
				<TaskAppTemplate
					queue={queue}
					unit={unit}
					unitRequest={unitRequest}
					next={next}
					skip={skipUnit}
					notAvailable={notAvailable}
					sendAnswer={send}
					task={task}
					isQueueOpen={isQueueOpen}
				/>
			</Layout>
		)
	} else {
		return <NavigateToNextTask goToNextTask={goToNextTask} />
	}
}

const NavigateToNextTask = ({ goToNextTask }: any) => {
	useTimeout(goToNextTask, 5000)
	return (
		<div className='text-muted'>
			You will be automaticaly redirect to next task in 5 seconds
		</div>
	)
}
