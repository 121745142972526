import mixpanel from 'mixpanel-browser'
import React from 'react'

import { Button } from '@cmpkit/base'

import googleLogo from '@/assets/images/google_logo.png'

type WorkspaceGoogleButton = {
	onClick: () => void
	disabled?: boolean
}
export default function WorkspaceGoogleButton({
	onClick,
	disabled,
}: WorkspaceGoogleButton) {
	return (
		<Button
			className='flex flex-1'
			disabled={disabled}
			size='large'
			iconBefore={
				<img className='mr-2' height={20} width={20} src={googleLogo} />
			}
			onClick={() => {
				mixpanel.track('Google button clicked')
				onClick()
			}}
		>
			Open in Google
		</Button>
	)
}
