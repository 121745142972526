import { LazyMotion } from 'framer-motion'
import * as React from 'react'
import { Toaster } from 'react-hot-toast'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AuthProvider } from '@/features/auth/authentication'
import { queryClient } from '@/lib/query-client'

import Router from '../Router'

const loadFeatures = () =>
	import('../../utils/motion').then((res) => res.default)

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<>
				<AuthProvider>
					<LazyMotion features={loadFeatures}>
						<Router />
					</LazyMotion>
				</AuthProvider>
				<ReactQueryDevtools />
				<Toaster />
			</>
		</QueryClientProvider>
	)
}
export default App
