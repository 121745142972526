export type PublicEnv = {
	ENVIRONMENT: string | undefined
	VERSION: string | undefined
	SENTRY_DSN: string | undefined
	BUILD: string | undefined
}

const env: PublicEnv = {
	ENVIRONMENT: process.env.NODE_ENV,
	VERSION: process.env.REACT_APP_VERSION,
	SENTRY_DSN:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_SENTRY_DSN
			: undefined,
	BUILD: process.env.REACT_APP_BUILD,
}

console.log(env.VERSION + '/' + env.BUILD)

export default env
