import { Result } from '@cmpkit/base'
import AlertIcon from '@cmpkit/icon/lib/glyph/alert'

import FullscreenLoading from '@/components/FullscreenLoading'
import { AuthUserModel } from '@/generated'
import { initReactQueryAuth } from '@/lib/react-query-auth'
import intl from '@/locale'
import { client } from '@/network/client'

async function loadUser(): Promise<AuthUserModel> {
	return await client.auth.getSession({ _c: new Date().getTime() })
}

async function logoutFn() {
	await client.auth.logout()
	window.location.assign(window.location.origin as unknown as string)
}

const authConfig = {
	loadUser,
	logoutFn,
	ErrorComponent: ({ error }: any) => {
		return (
			<Result
				className={'m-auto'}
				icon={<AlertIcon width={80} height={80} />}
				title={intl
					.get('app.unexpected_error.title')
					.defaultMessage('Something Unexpected Happened')}
				subtitle={intl
					.get('app.unexpected_error.text')
					.defaultMessage(
						'Sorry, an unrecoverable error occurred . Please try reloading the page, it may have been a temporary glitch.'
					)}
			>
				<div className='flex flex-col space-y-2'>
					<pre>{JSON.stringify(error, null, 2)}</pre>
				</div>
			</Result>
		)
	},
	LoaderComponent() {
		return <FullscreenLoading />
	},
}

export const { AuthProvider, useAuth } = initReactQueryAuth<
	AuthUserModel,
	Error
>(authConfig)
