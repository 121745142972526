import env from '@/env'
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn: env.SENTRY_DSN,
	environment: env.ENVIRONMENT,
	release: `${env.VERSION}.${env.BUILD}`,
	autoSessionTracking: false,
	normalizeDepth: 10,
	ignoreErrors: ['AxiosError', 'NetworkError'],
	denyUrls: [
		/extensions\//i,
		/^chrome:\/\//i,
		/api\.amplitude\.com/i,
		/app\.satismeter\.com/i,
		/moz-extension:/i,
		/localhost/i,
		/api-js\.mixpanel\.com/i,
	],
})
export default Sentry
