import { Base } from '@/generated'
import { UseQueryOptions } from '@/lib/query-client'
import { client } from '@/network/client'
import { useQuery } from '@tanstack/react-query'

export function useUserStatisticsQuery<T = Base.GetUserStatistics.ResponseBody>(
	period: any,
	options?: UseQueryOptions<Base.GetUserStatistics.ResponseBody, any, T>
) {
	return useQuery<Base.GetUserStatistics.ResponseBody, any, T>({
		queryKey: ['user_statistics', period],
		queryFn: () =>
			client.base.getUserStatistics({
				...period,
				_c: new Date().getTime(),
			}),
		...(options || {}),
	})
}

export function useUserTiersQuery<T = Base.GetMyTiers.ResponseBody>(
	options?: UseQueryOptions<Base.GetMyTiers.ResponseBody, any, T>
) {
	return useQuery<Base.GetMyTiers.ResponseBody, any, T>({
		queryKey: ['user_tiers'],
		queryFn: () => client.base.getMyTiers(),
		...(options || {}),
	})
}

export function useTiersQuery<T = Base.GetTiers.ResponseBody>(
	options?: UseQueryOptions<Base.GetTiers.ResponseBody, any, T>
) {
	return useQuery<Base.GetTiers.ResponseBody, any, T>({
		queryKey: ['tiers'],
		queryFn: () => client.base.getTiers(),
		...(options || {}),
	})
}
