/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TierModel {
	cost: number
	name: string
	id: number
	units_amount: number
}

export interface UserTierModel {
	cost: number
	name: string
	start_date: string
	units_amount: number
}

export interface AuthUserModel {
	/** Success */
	success: boolean
	/** Id */
	id: number
	/** Group */
	group: string
	/** Email */
	email: string
	/** Login */
	login: string
	/** Group Id */
	group_id: number
}

export interface TasksItemModel {
	active: boolean
	available: number
	id: number
	name: string
	skipped: number
	task_type: string
	tags?: string
	taskapp_id: number
	taskapp_name: string
	taskpriority: number
	total: number
}

export interface TasksModel {
	items: TasksItemModel[]
	taskapp: string
	taskapp_id: string
}

export interface AnswerBody {
	action?: string
	response?: string
	task_id?: number
	execution_eta?: number
	meta?: string
	id?: number
}

export interface TaskUnitItemModel {
	priority?: number | null
	old_response?: string
	taskapp_id: number
	meta: string
	request: string
	task_id: number
	status: string
	parent_worker_id?: number | null
	url_regex: string
	ref_worker_id: (number | null)[]
	url_option_regex: string
	id: number
	representation: string
}

export interface TaskModel {
	taskapp_id: number
	tags?: any[] | null
	units_skipped: number
	id: number
	taskpriority: number
	assignment_length: number
	taskapp: string
	comments?: string
	url_pattern?: string
	items: TaskUnitItemModel[]
	title: string
	cache_length: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
	/** Detail */
	detail?: ValidationError[]
}

/** ValidationError */
export interface ValidationError {
	/** Location */
	loc: any[]
	/** Message */
	msg: string
	/** Error Type */
	type: string
}

export interface GetSessionParams {
	/** Salt */
	_c: number
}

export interface LoginPayload {
	login?: string
	passwd?: string
}

export interface LoginParams {
	/** Salt */
	_c: number
}

export interface ListTasksParams {
	/** Status */
	status: string
	/** Salt */
	_c: number
}

export interface NextUnitsParams {
	/** offset */
	offset: number
	/** count */
	count: number
	/** Salt server */
	salt: number
	/** Salt */
	_c: number
	taskId: string
}

export interface SkipUnitPayload {
	unit_id?: number
}

export interface UpdateMyTiersPayload {
	current_tier?: UserTierModel
	next_month_tier?: UserTierModel
}

export interface UpdateProfilePayload {
	email?: string
	password?: string
}

export interface GetUserStatisticsParams {
	/** start_date */
	start_date: string
	/** end_date */
	end_date: string
	/** Salt */
	_c: number
}

export interface GetInstructionsParams {
	/** campaign_id */
	campaign_id: string
	/** category_id */
	category_id: string
	/** competitor_id */
	competitor_id: string
	/** Salt */
	_c: number
}

export interface GetAnswersParams {
	/** campaign_id */
	campaign_id: string
	/** category_id */
	category_id: string
	/** competitor_id */
	competitor_id: string
	/** Salt */
	_c: number
}

export interface SendAnswer1Payload {
	body?: string
}

export interface SendAnswer1Params {
	/** Salt */
	_c: number
}

export interface GetCategoriesParams {
	/** campaign_id */
	campaign_id: string
	/** id */
	id?: string
	/** Salt */
	_c: number
}

export namespace Auth {
	/**
	 * No description
	 * @tags auth
	 * @name GetSession
	 * @summary Get user auth meta
	 * @request GET:/api/meta
	 * @secure
	 * @response `200` `AuthUserModel` Successful Response
	 */
	export namespace GetSession {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = AuthUserModel
	} /**
 * No description
 * @tags auth
 * @name Login
 * @summary Login
 * @request POST:/api/login
 * @response `200` `{
    success?: boolean,

}` Validation Error
*/
	export namespace Login {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Salt */
			_c: number
		}
		export type RequestBody = LoginPayload
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	}
	/**
	 * No description
	 * @tags auth
	 * @name Logout
	 * @summary Logout
	 * @request GET:/api/logout
	 * @secure
	 * @response `200` `void` Successful Response
	 */
	export namespace Logout {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = void
	}
}

export namespace Work {
	/**
	 * No description
	 * @tags work
	 * @name ListTasks
	 * @summary Tasks
	 * @request GET:/api/tasks
	 * @response `200` `TasksModel` Successful Response
	 */
	export namespace ListTasks {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Status */
			status: string
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = TasksModel
	}
	/**
	 * No description
	 * @tags work
	 * @name NextUnits
	 * @summary Get Current User Handler
	 * @request GET:/api/units/next/{task_id}
	 * @secure
	 * @response `200` `any` Successful Response
	 */
	export namespace NextUnits {
		export type RequestParams = {
			taskId: string
		}
		export type RequestQuery = {
			/** offset */
			offset: number
			/** count */
			count: number
			/** Salt server */
			salt: number
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = any
	} /**
 * No description
 * @tags work
 * @name SendAnswer
 * @request POST:/api/units/answer
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace SendAnswer {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = AnswerBody
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	} /**
 * No description
 * @tags work
 * @name SkipUnit
 * @summary Skip unit
 * @request POST:/api/nav/skip
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace SkipUnit {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = SkipUnitPayload
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	}
}

export namespace Util {
	/**
	 * No description
	 * @tags util
	 * @name GetServerTime
	 * @summary Get Current server time
	 * @request GET:/api/current_datetime
	 * @response `200` `Record<string,number>` Successful Response
	 */
	export namespace GetServerTime {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = Record<string, number>
	} /**
 * No description
 * @tags util
 * @name GetCategories
 * @summary Get categories
 * @request GET:/redirect-api/instructions-service-redirect/api/categories/
 * @response `200` `{
    objects?: ({
    parent?: string,
    id?: string,
    name?: string,

})[],

}` Successful Response
*/
	export namespace GetCategories {
		export type RequestParams = {}
		export type RequestQuery = {
			/** campaign_id */
			campaign_id: string
			/** id */
			id?: string
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			objects?: {
				parent?: string
				id?: string
				name?: string
			}[]
		}
	}
}

export namespace Base {
	/**
 * No description
 * @tags base
 * @name GetMyTiers
 * @request GET:/api/mytiers
 * @response `200` `{
    current_tier?: UserTierModel,
    next_month_tier?: UserTierModel,

}`
*/
	export namespace GetMyTiers {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			current_tier?: UserTierModel
			next_month_tier?: UserTierModel
		}
	} /**
 * No description
 * @tags base
 * @name UpdateMyTiers
 * @request PUT:/api/mytiers
 * @response `200` `{
    current_tier?: UserTierModel,
    next_month_tier?: UserTierModel,

}`
*/
	export namespace UpdateMyTiers {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UpdateMyTiersPayload
		export type RequestHeaders = {}
		export type ResponseBody = {
			current_tier?: UserTierModel
			next_month_tier?: UserTierModel
		}
	} /**
 * No description
 * @tags base
 * @name GetTiers
 * @request GET:/api/tiers
 * @response `200` `{
    items?: (TierModel)[],

}`
*/
	export namespace GetTiers {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			items?: TierModel[]
		}
	} /**
 * No description
 * @tags base
 * @name UpdateProfile
 * @summary Update profile
 * @request POST:/api/profile
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace UpdateProfile {
		export type RequestParams = {}
		export type RequestQuery = {}
		export type RequestBody = UpdateProfilePayload
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	} /**
 * No description
 * @tags base
 * @name GetUserStatistics
 * @summary Get user statistics
 * @request GET:/api/userstatistics
 * @response `200` `{
    current_month_tier_accomplished?: number,
    current_month_tier_committed?: number,
    period_error_count?: number,
    period_quality_rate?: number,
    period_units_done?: number,

}` Successful Response
*/
	export namespace GetUserStatistics {
		export type RequestParams = {}
		export type RequestQuery = {
			/** start_date */
			start_date: string
			/** end_date */
			end_date: string
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = {
			current_month_tier_accomplished?: number
			current_month_tier_committed?: number
			period_error_count?: number
			period_quality_rate?: number
			period_units_done?: number
		}
	}
}

export namespace Instructions {
	/**
	 * No description
	 * @tags instructions
	 * @name GetInstructions
	 * @summary Get instructions
	 * @request GET:/redirect-api/instructions-service-redirect/instructions/
	 * @response `200` `string` Successful Response
	 */
	export namespace GetInstructions {
		export type RequestParams = {}
		export type RequestQuery = {
			/** campaign_id */
			campaign_id: string
			/** category_id */
			category_id: string
			/** competitor_id */
			competitor_id: string
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = string
	}
	/**
	 * No description
	 * @tags instructions
	 * @name GetAnswers
	 * @summary Get instructions
	 * @request GET:/redirect-api/instructions-service-redirect/user_answers/
	 * @response `200` `string` Successful Response
	 */
	export namespace GetAnswers {
		export type RequestParams = {}
		export type RequestQuery = {
			/** campaign_id */
			campaign_id: string
			/** category_id */
			category_id: string
			/** competitor_id */
			competitor_id: string
			/** Salt */
			_c: number
		}
		export type RequestBody = never
		export type RequestHeaders = {}
		export type ResponseBody = string
	} /**
 * No description
 * @tags instructions
 * @name SendAnswer1
 * @summary Get instructions
 * @request POST:/redirect-api/instructions-service-redirect/user_answers/
 * @response `200` `{
    success?: boolean,

}` Successful Response
*/
	export namespace SendAnswer1 {
		export type RequestParams = {}
		export type RequestQuery = {
			/** Salt */
			_c: number
		}
		export type RequestBody = SendAnswer1Payload
		export type RequestHeaders = {}
		export type ResponseBody = {
			success?: boolean
		}
	}
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean
	/** request path */
	path: string
	/** content type of request body */
	type?: ContentType
	/** query params */
	query?: QueryParamsType
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType
	/** request body */
	body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
	secure?: boolean
	format?: ResponseType
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance
	private securityData: SecurityDataType | null = null
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
	private secure?: boolean
	private format?: ResponseType

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
		this.secure = secure
		this.format = format
		this.securityWorker = securityWorker
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data
	}

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method)

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		}
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem)
		} else {
			return `${formItem}`
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key]
			const propertyContent: any[] = property instanceof Array ? property : [property]

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
			}

			return formData
		}, new FormData())
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{}
		const requestParams = this.mergeRequestParams(params, secureParams)
		const responseFormat = format || this.format || undefined

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>)
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body)
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path,
			})
			.then((response) => response.data)
	}
}

/**
 * @title WB service
 * @version 0.1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	auth = {
		/**
		 * No description
		 *
		 * @tags auth
		 * @name GetSession
		 * @summary Get user auth meta
		 * @request GET:/api/meta
		 * @secure
		 * @response `200` `AuthUserModel` Successful Response
		 */
		getSession: (query: GetSessionParams, params: RequestParams = {}) =>
			this.request<AuthUserModel, any>({
				path: `/api/meta`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags auth
 * @name Login
 * @summary Login
 * @request POST:/api/login
 * @response `200` `{
    success?: boolean,

}` Validation Error
 */
		login: (query: LoginParams, data: LoginPayload, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/login`,
				method: 'POST',
				query: query,
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name Logout
		 * @summary Logout
		 * @request GET:/api/logout
		 * @secure
		 * @response `200` `void` Successful Response
		 */
		logout: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/logout`,
				method: 'GET',
				secure: true,
				...params,
			}),
	}
	work = {
		/**
		 * No description
		 *
		 * @tags work
		 * @name ListTasks
		 * @summary Tasks
		 * @request GET:/api/tasks
		 * @response `200` `TasksModel` Successful Response
		 */
		listTasks: (query: ListTasksParams, params: RequestParams = {}) =>
			this.request<TasksModel, any>({
				path: `/api/tasks`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags work
		 * @name NextUnits
		 * @summary Get Current User Handler
		 * @request GET:/api/units/next/{task_id}
		 * @secure
		 * @response `200` `any` Successful Response
		 */
		nextUnits: ({ taskId, ...query }: NextUnitsParams, params: RequestParams = {}) =>
			this.request<any, any>({
				path: `/api/units/next/${taskId}`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags work
 * @name SendAnswer
 * @request POST:/api/units/answer
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		sendAnswer: (data: AnswerBody, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/units/answer`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags work
 * @name SkipUnit
 * @summary Skip unit
 * @request POST:/api/nav/skip
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		skipUnit: (data: SkipUnitPayload, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/nav/skip`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),
	}
	util = {
		/**
		 * No description
		 *
		 * @tags util
		 * @name GetServerTime
		 * @summary Get Current server time
		 * @request GET:/api/current_datetime
		 * @response `200` `Record<string,number>` Successful Response
		 */
		getServerTime: (params: RequestParams = {}) =>
			this.request<Record<string, number>, any>({
				path: `/api/current_datetime`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags util
 * @name GetCategories
 * @summary Get categories
 * @request GET:/redirect-api/instructions-service-redirect/api/categories/
 * @response `200` `{
    objects?: ({
    parent?: string,
    id?: string,
    name?: string,

})[],

}` Successful Response
 */
		getCategories: (query: GetCategoriesParams, params: RequestParams = {}) =>
			this.request<
				{
					objects?: {
						parent?: string
						id?: string
						name?: string
					}[]
				},
				any
			>({
				path: `/redirect-api/instructions-service-redirect/api/categories/`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),
	}
	base = {
		/**
 * No description
 *
 * @tags base
 * @name GetMyTiers
 * @request GET:/api/mytiers
 * @response `200` `{
    current_tier?: UserTierModel,
    next_month_tier?: UserTierModel,

}`
 */
		getMyTiers: (params: RequestParams = {}) =>
			this.request<
				{
					current_tier?: UserTierModel
					next_month_tier?: UserTierModel
				},
				any
			>({
				path: `/api/mytiers`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags base
 * @name UpdateMyTiers
 * @request PUT:/api/mytiers
 * @response `200` `{
    current_tier?: UserTierModel,
    next_month_tier?: UserTierModel,

}`
 */
		updateMyTiers: (data: UpdateMyTiersPayload, params: RequestParams = {}) =>
			this.request<
				{
					current_tier?: UserTierModel
					next_month_tier?: UserTierModel
				},
				any
			>({
				path: `/api/mytiers`,
				method: 'PUT',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags base
 * @name GetTiers
 * @request GET:/api/tiers
 * @response `200` `{
    items?: (TierModel)[],

}`
 */
		getTiers: (params: RequestParams = {}) =>
			this.request<
				{
					items?: TierModel[]
				},
				any
			>({
				path: `/api/tiers`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags base
 * @name UpdateProfile
 * @summary Update profile
 * @request POST:/api/profile
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		updateProfile: (data: UpdateProfilePayload, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/api/profile`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags base
 * @name GetUserStatistics
 * @summary Get user statistics
 * @request GET:/api/userstatistics
 * @response `200` `{
    current_month_tier_accomplished?: number,
    current_month_tier_committed?: number,
    period_error_count?: number,
    period_quality_rate?: number,
    period_units_done?: number,

}` Successful Response
 */
		getUserStatistics: (query: GetUserStatisticsParams, params: RequestParams = {}) =>
			this.request<
				{
					current_month_tier_accomplished?: number
					current_month_tier_committed?: number
					period_error_count?: number
					period_quality_rate?: number
					period_units_done?: number
				},
				any
			>({
				path: `/api/userstatistics`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),
	}
	instructions = {
		/**
		 * No description
		 *
		 * @tags instructions
		 * @name GetInstructions
		 * @summary Get instructions
		 * @request GET:/redirect-api/instructions-service-redirect/instructions/
		 * @response `200` `string` Successful Response
		 */
		getInstructions: (query: GetInstructionsParams, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/redirect-api/instructions-service-redirect/instructions/`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags instructions
		 * @name GetAnswers
		 * @summary Get instructions
		 * @request GET:/redirect-api/instructions-service-redirect/user_answers/
		 * @response `200` `string` Successful Response
		 */
		getAnswers: (query: GetAnswersParams, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/redirect-api/instructions-service-redirect/user_answers/`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
 * No description
 *
 * @tags instructions
 * @name SendAnswer1
 * @summary Get instructions
 * @request POST:/redirect-api/instructions-service-redirect/user_answers/
 * @response `200` `{
    success?: boolean,

}` Successful Response
 */
		sendAnswer1: (query: SendAnswer1Params, data: SendAnswer1Payload, params: RequestParams = {}) =>
			this.request<
				{
					success?: boolean
				},
				any
			>({
				path: `/redirect-api/instructions-service-redirect/user_answers/`,
				method: 'POST',
				query: query,
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	}
}
