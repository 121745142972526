import React from 'react'
import { useNavigate } from 'react-router'

import { Button, Result } from '@cmpkit/base'
import AwardIcon from '@cmpkit/icon/lib/glyph/award'

import intl from '@/locale'

export default function WelcomeScene() {
	const navigate = useNavigate()
	return (
		<Result
			className={'m-auto'}
			icon={<AwardIcon width={80} height={80} />}
			title={intl
				.get('app.welcome.title')
				.defaultMessage('Welcome to WORKBENCH')}
			/* subtitle={intl
				.get('app.welcome.text')
				.defaultMessage('Lets make an awesome product')} */
		>
			<Button size='large' onClick={() => navigate('/app/workspace')}>
				Lets get to work
			</Button>
		</Result>
	)
}
