import { endOfMonth, format, startOfMonth } from 'date-fns'
import { prop } from 'lodash/fp'
import mixpanel from 'mixpanel-browser'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Badge, Button, Input } from '@cmpkit/base'
import ArrowRightIcon from '@cmpkit/icon/lib/glyph/arrow-right'
import RepricingIcon from '@cmpkit/icon/lib/glyph/repricing'
import XCircleIcon from '@cmpkit/icon/lib/glyph/x-circle'

import { useUserStatisticsQuery } from '@/features/account/queries'
import { useAuth } from '@/features/auth/authentication'
import { downloadFile } from '@/utils/helpers'

export default function ProfileStatistics() {
	const { user } = useAuth()
	const defaultValues = getDefaultPeriod()
	const [period, setPeriod] = useState(defaultValues)
	const { register, handleSubmit } = useForm({
		defaultValues,
	})
	const statQuery = useUserStatisticsQuery(period)

	return (
		<div>
			<form onSubmit={handleSubmit(setPeriod)}>
				<div className='flex space-x-2 items-center'>
					<div className='w-40'>
						<Input {...register('start_date')} type='date' />
					</div>
					<ArrowRightIcon className='shrink-0' />
					<div className='w-40'>
						<Input {...register('end_date')} type='date' className='w-40' />
					</div>
					<Button variant='primary' type='submit'>
						Apply
					</Button>
				</div>
			</form>
			{statQuery.isLoading && 'Loading...'}
			{statQuery.data && (
				<div className='mt-10'>
					<h2 className='font-bold text-lg mb-3'>Plain statistic</h2>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>Period</span>:
						<Badge>{format(new Date(period.start_date), 'dd MMMM yyyy')}</Badge>
						<ArrowRightIcon className='shrink-0' />
						<Badge>{format(new Date(period.end_date), 'dd MMMM yyyy')}</Badge>
					</div>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>Done Matches Count</span>
						: <strong>{prop('period_units_done', statQuery.data)}</strong>
					</div>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>Found Errors Count</span>
						: <strong>{prop('period_error_count', statQuery.data)}</strong>
					</div>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>Quality percentage</span>
						: <strong>{prop('period_quality_rate', statQuery.data)}</strong>
					</div>
					<div className='flex space-x-2 mt-2'>
						<Button
							variant='primary'
							iconBefore={<RepricingIcon />}
							onClick={() => {
								const start_date = format(
									new Date(period.start_date),
									dateFormat
								)
								const end_date = format(new Date(period.end_date), dateFormat)
								mixpanel.track('Download Salary report', {
									start_date,
									end_date,
								})
								user && downloadSalaryReport(user.id, start_date, end_date)
							}}
						>
							Download Salary report
						</Button>
						<Button
							variant='warn'
							iconBefore={<XCircleIcon />}
							onClick={() => {
								const start_date = format(
									new Date(period.start_date),
									dateFormat
								)
								const end_date = format(new Date(period.end_date), dateFormat)
								mixpanel.track('Download Invalid units report', {
									start_date,
									end_date,
								})
								user && downloadUserErrorReport(user.id, start_date, end_date)
							}}
						>
							Download Invalid units report
						</Button>
					</div>
					<h2 className='font-bold text-lg mb-3 mt-5'>
						Tier accomplishment statistic
					</h2>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>This month tier</span>:{' '}
						<strong>
							{prop('current_month_tier_committed', statQuery.data)}
						</strong>
					</div>
					<div className='h-6 space-x-2'>
						<span className='text-muted font-semibold'>
							This month accomplished units
						</span>
						:
						<strong>
							{prop('current_month_tier_accomplished', statQuery.data)}
						</strong>
					</div>
				</div>
			)}
		</div>
	)
}
const downloadUserErrorReport = (
	workerId: number,
	startDate: string,
	endDate: string
) => {
	return downloadFile(
		`/wbstats-redirect/api/unit_errors/report?format=excel&worker_id=${workerId}&start_date=${startDate}&end_date=${endDate}`
	)
}
const downloadSalaryReport = (
	workerId: number,
	startDate: string,
	endDate: string
) => {
	return downloadFile(
		`/api/report/salary-report?export_format=csv&worker_id=${workerId}&start_date=${startDate}&end_date=${endDate}`
	)
}
const dateFormat = 'yyyy-MM-dd'
const getDefaultPeriod = () => ({
	start_date: format(startOfMonth(new Date()), dateFormat),
	end_date: format(endOfMonth(new Date()), dateFormat),
})
