import cx from 'clsx'
import React from 'react'

export default function WorkspaceActionButton({
	onClick,
	variant,
	active,
	children,
}: any) {
	return (
		<button
			onClick={onClick}
			type='button'
			className={cx('action-btn', `action-btn--${variant}`, {
				active,
			})}
		>
			{children}
		</button>
	)
}
