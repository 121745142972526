import { AnswerBody } from '@/generated'
import { MutationConfig } from '@/lib/query-client'
import { client } from '@/network/client'
import { useMutation } from '@tanstack/react-query'

export function useSendAnswerMutation(options?: MutationConfig<any>) {
	return useMutation<any, any, AnswerBody>({
		mutationFn: (answer) => client.work.sendAnswer(answer),
		mutationKey: ['work', 'answer'],
		...options,
	})
}
