import { filter, map, pipe, prop } from 'lodash/fp'
import React, { useMemo } from 'react'

//import { Loader } from '@cmpkit/base'
import { Modal, ModalTransition } from '@cmpkit/modal'

import WorkspaceQuiz from '@/scenes/Workspace/WorkspaceQuiz'
import { parseJson } from '@/utils/helpers'

import { useAuth } from '../auth/authentication'
import { useWorkspace } from '../workspace/context'
import { useInstructionsAnswersQuery, useInstructionsQuery } from './queries'
import { InstructionAnswerModel, InstructionModel } from './types'
import { decodeInstructionsData } from './utils'

export interface QuizContextValue {
	instructions?: InstructionModel[]
	answers?: InstructionAnswerModel[]
}
export interface QuizProviderProps {
	children: React.ReactNode
}
export const QuizContext = React.createContext<QuizContextValue | null>(null)

export function QuizProvider({ children }: QuizProviderProps): JSX.Element {
	/**
	 * Common hooks
	 */
	const { user } = useAuth()
	const { unit } = useWorkspace()

	/**
	 * Unit parsed request
	 */
	const unitRequest = unit ? parseJson(unit.request) : {}
	const { category_id, competitor_id, campaign_id }: any = unitRequest

	/**
	 * Queries
	 */
	const instructionsQuery = useInstructionsQuery<InstructionModel[]>(
		{ category_id, competitor_id, campaign_id },
		{
			enabled: Boolean(category_id || competitor_id || campaign_id),
			select: pipe([decodeInstructionsData, prop('body')]),
		}
	)
	const answersQuery = useInstructionsAnswersQuery<InstructionAnswerModel[]>(
		{
			user_id: (user as any).id,
		},
		{
			select: pipe([decodeInstructionsData, prop('body')]),
		}
	)

	/**
	 * Select not answered instructions
	 */
	const notAnsweredInstructions = selectNotAnsweredInstructions(
		answersQuery.data || [],
		instructionsQuery.data || []
	)
	/* const quizInstruction = selectQuizInstruction(
		notAnsweredInstructions,
		unitRequest
	) */

	/**
	 * Context value
	 */
	const value = useMemo(
		() => ({
			instructions: instructionsQuery.data,
			answers: answersQuery.data,
		}),
		[answersQuery, instructionsQuery]
	)

	/**
	 * Ensure that we have all the data
	 */

	/* if (
		(!instructionsQuery.isStale && instructionsQuery.isLoading) ||
		answersQuery.isLoading
	) {
		return (
			<div className='h-full w-full flex flex-col items-center justify-center '>
				<Loader />
				<p className='text-muted mt-2 text-xs'>Checking instructions...</p>
			</div>
		)
	} */
	const isCheckingInstructions =
		(!instructionsQuery.isStale && instructionsQuery.isLoading) ||
		answersQuery.isLoading

	const showQuiz = notAnsweredInstructions.length > 0 && !isCheckingInstructions

	return (
		<QuizContext.Provider value={value as any}>
			{/* {notAnsweredInstructions.length > 0 ? (
				<WorkspaceQuiz
					unit={unitRequest}
					instructions={notAnsweredInstructions}
				/>
			) : (
				children
			)} */}
			<ModalTransition>
				{showQuiz && (
					<Modal zIndex={100000} width='900'>
						<WorkspaceQuiz
							unit={unitRequest}
							instructions={notAnsweredInstructions}
						/>
					</Modal>
				)}
			</ModalTransition>
			{children}
		</QuizContext.Provider>
	)
}
export function useQuiz() {
	const context = React.useContext(QuizContext)
	if (!context) {
		throw new Error(`useQuiz must be used within an QuizProvider`)
	}
	return context
}

const selectNotAnsweredInstructions = (
	answers: InstructionAnswerModel[],
	instructions: InstructionModel[]
) => {
	const resolvedInstructions = map(prop('instruction_id'), answers)
	return filter((i: any) => !resolvedInstructions.includes(i.id), instructions)
}
